<template>
  <div class="template2">    
    <table class="viewTemplate" :style="`--bg:${colors.color_fondo}; --font:${colors.color_fuente};`">
      <thead>
        <tr>
            <td>
              <header class="viewTemplate__header">
                <figure class="header__porfileImg">
                  <template v-if="userData.foto_persona">
                    <img :src="userData.foto_persona" :alt="userData.primer_nombre" />
                  </template>
                  <img v-else src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="foto" />
                </figure>
                <article class="header__infoCon">
                  <div class="header__infoCon__person">
                      <div>
                      <h1>{{userData.primer_nombre}}</h1>
                      <p>Agente Inmobilario</p>
                      </div>
                      <div class="socialMedia">
                        <a v-if="userData.celular != null" class="linkIcon" :href="`tel:${userData.celular}`">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M304 0h-224c-35.35 0-64 28.65-64 64v384c0 35.35 28.65 64 64 64h224c35.35 0 64-28.65 64-64V64C368 28.65 339.3 0 304 0zM192 480c-17.75 0-32-14.25-32-32s14.25-32 32-32s32 14.25 32 32S209.8 480 192 480zM304 64v320h-224V64H304z"
                            />
                            </svg>
                        </a>
                        <a v-if="userData.email != null" class="linkIcon" :href="`mailto:${userData.email}`">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
                            />
                            </svg>
                        </a>
                        <a v-if="userData.facebook != null" class="linkIcon" :href="userData.facebook" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                            />
                            </svg>
                        </a>
                        <a v-if="userData.instagram != null" class="linkIcon" :href="userData.instagram" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                            />
                            </svg>
                        </a>
                        <a v-if="userData.linkedin != null" class="linkIcon" :href="userData.linkedin" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                            />
                            </svg>
                        </a>
                      </div>
                  </div>
                  <div class="header__infoCon__place">
                      <h2>{{empresa.nombre}}</h2>
                      <p>{{empresa.direccion}}</p>
                  </div>
                </article>
              </header>
            </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <main class="viewTemplate__body">
              <div class="descripcionPerfil" v-html="userData.descripcion">
              </div>
            </main>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
        <td>
            <footer class="viewTemplate__footer">
            <figure class="footer__company">
                <img :src="empresa.logo" alt="" />
            </figure>
            <figure class="footer__imgDec">
              <svg
                id="Objects"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 515.88 570.58"
              >
                <g>
                  <polygon
                    class="cls-1"
                    points="365.98 30.84 375.29 33.85 375.29 24.54 365.98 21.53 365.98 30.84"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 68.39 375.29 71.15 375.29 61.85 365.98 59.09 365.98 68.39"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 87.17 375.29 89.8 375.29 80.5 365.98 77.86 365.98 87.17"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 105.95 375.29 108.46 375.29 99.15 365.98 96.64 365.98 105.95"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 124.72 375.29 127.11 375.29 117.8 365.98 115.42 365.98 124.72"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 162.28 375.29 164.41 375.29 155.1 365.98 152.97 365.98 162.28"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 181.05 375.29 183.06 375.29 173.76 365.98 171.75 365.98 181.05"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 199.83 375.29 201.71 375.29 192.41 365.98 190.52 365.98 199.83"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 218.61 375.29 220.36 375.29 211.06 365.98 209.3 365.98 218.61"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 237.38 375.29 239.02 375.29 229.71 365.98 228.08 365.98 237.38"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 256.16 375.29 257.67 375.29 248.36 365.98 246.86 365.98 256.16"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 274.94 375.29 276.32 375.29 267.01 365.98 265.63 365.98 274.94"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 293.71 375.29 294.97 375.29 285.66 365.98 284.41 365.98 293.71"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 312.49 375.29 313.62 375.29 304.32 365.98 303.19 365.98 312.49"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 331.27 375.29 332.27 375.29 322.97 365.98 321.96 365.98 331.27"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 350.05 375.29 350.92 375.29 341.62 365.98 340.74 365.98 350.05"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 406.38 375.29 406.88 375.29 397.57 365.98 397.07 365.98 406.38"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 425.15 375.29 425.53 375.29 416.22 365.98 415.85 365.98 425.15"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 462.71 375.29 462.83 375.29 453.53 365.98 453.4 365.98 462.71"
                  />
                  <polygon
                    class="cls-1"
                    points="365.98 561.14 375.29 561.27 375.29 551.96 365.98 551.83 365.98 561.14"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="378.76 34.97 388.07 37.98 388.07 28.68 378.76 25.67 378.76 34.97"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 47.16 378.76 44.27 378.76 53.58 388.07 56.46 388.07 47.16"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 65.64 378.76 62.88 378.76 72.18 388.07 74.94 388.07 65.64"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 84.12 378.76 81.48 378.76 90.79 388.07 93.42 388.07 84.12"
                  />
                  <polygon
                    class="cls-1"
                    points="388.06 102.6 378.76 100.09 378.76 109.39 388.06 111.9 388.06 102.6"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 139.55 378.76 137.3 378.76 146.6 388.07 148.86 388.07 139.55"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 158.03 378.76 155.9 378.76 165.21 388.07 167.34 388.07 158.03"
                  />
                  <polygon
                    class="cls-1"
                    points="388.06 176.51 378.76 174.5 378.76 183.81 388.06 185.82 388.06 176.51"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 213.47 378.76 211.71 378.76 221.02 388.07 222.78 388.07 213.47"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 250.43 378.76 248.92 378.76 258.23 388.07 259.74 388.07 250.43"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 268.91 378.76 267.53 378.76 276.83 388.07 278.21 388.07 268.91"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 287.39 378.76 286.13 378.76 295.44 388.07 296.69 388.07 287.39"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 305.87 378.76 304.74 378.76 314.04 388.07 315.17 388.07 305.87"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 342.82 378.76 341.95 378.76 351.25 388.07 352.13 388.07 342.82"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 361.3 378.76 360.55 378.76 369.86 388.07 370.61 388.07 361.3"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 416.74 378.76 416.36 378.76 425.67 388.07 426.05 388.07 416.74"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 435.22 378.76 434.97 378.76 444.28 388.07 444.53 388.07 435.22"
                  />
                  <polygon
                    class="cls-1"
                    points="388.07 453.7 378.76 453.57 378.76 462.88 388.07 463 388.07 453.7"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="391.54 94.41 400.84 97.04 400.84 87.73 391.54 85.1 391.54 94.41"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 112.84 400.84 115.35 400.84 106.04 391.54 103.53 391.54 112.84"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 149.7 400.85 151.96 400.85 142.66 391.54 140.4 391.54 149.7"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 168.14 400.85 170.27 400.85 160.96 391.54 158.83 391.54 168.14"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 205 400.84 206.88 400.84 197.58 391.54 195.69 391.54 205"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 223.43 400.85 225.19 400.85 215.88 391.54 214.13 391.54 223.43"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 241.86 400.84 243.5 400.84 234.19 391.54 232.56 391.54 241.86"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 297.16 400.85 298.42 400.85 289.11 391.54 287.86 391.54 297.16"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 315.59 400.84 316.72 400.84 307.42 391.54 306.29 391.54 315.59"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 334.03 400.84 335.03 400.84 325.72 391.54 324.72 391.54 334.03"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 352.46 400.85 353.34 400.85 344.03 391.54 343.15 391.54 352.46"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 370.89 400.85 371.64 400.85 362.34 391.54 361.58 391.54 370.89"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 389.32 400.84 389.95 400.84 380.64 391.54 380.02 391.54 389.32"
                  />
                  <polygon
                    class="cls-1"
                    points="391.54 426.19 400.85 426.56 400.85 417.26 391.54 416.88 391.54 426.19"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="404.32 61.51 413.62 64.39 413.62 55.08 404.32 52.2 404.32 61.51"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 79.76 413.62 82.53 413.62 73.22 404.32 70.46 404.32 79.76"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 116.29 413.62 118.8 413.62 109.49 404.32 106.98 404.32 116.29"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 134.55 413.62 136.93 413.62 127.62 404.32 125.24 404.32 134.55"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 152.8 413.62 155.06 413.62 145.76 404.32 143.5 404.32 152.8"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 189.32 413.62 191.33 413.62 182.03 404.32 180.02 404.32 189.32"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 207.58 413.62 209.47 413.62 200.16 404.32 198.28 404.32 207.58"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 262.36 413.62 263.87 413.62 254.56 404.32 253.06 404.32 262.36"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 280.63 413.62 282 413.62 272.7 404.32 271.32 404.32 280.63"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 298.88 413.62 300.14 413.62 290.83 404.32 289.58 404.32 298.88"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 317.14 413.62 318.27 413.62 308.97 404.32 307.84 404.32 317.14"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 335.41 413.62 336.41 413.62 327.1 404.32 326.1 404.32 335.41"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 353.66 413.62 354.54 413.62 345.24 404.32 344.36 404.32 353.66"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 390.18 413.62 390.81 413.62 381.51 404.32 380.88 404.32 390.18"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 444.96 413.62 445.21 413.62 435.91 404.32 435.66 404.32 444.96"
                  />
                  <polygon
                    class="cls-1"
                    points="404.32 463.22 413.62 463.35 413.62 454.04 404.32 453.92 404.32 463.22"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="417.1 65.47 426.4 68.35 426.4 59.05 417.1 56.16 417.1 65.47"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 83.56 426.4 86.32 426.4 77.01 417.1 74.25 417.1 83.56"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 101.64 426.4 104.28 426.4 94.97 417.1 92.34 417.1 101.64"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 137.82 426.4 140.2 426.4 130.9 417.1 128.51 417.1 137.82"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 192.08 426.4 194.09 426.4 184.78 417.1 182.78 417.1 192.08"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 210.17 426.4 212.05 426.4 202.75 417.1 200.86 417.1 210.17"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 228.26 426.4 230.01 426.4 220.71 417.1 218.95 417.1 228.26"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 246.34 426.4 247.98 426.4 238.67 417.1 237.04 417.1 246.34"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 264.43 426.4 265.94 426.4 256.63 417.1 255.13 417.1 264.43"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 282.52 426.4 283.9 426.4 274.59 417.1 273.21 417.1 282.52"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 318.7 426.4 319.82 426.4 310.52 417.1 309.39 417.1 318.7"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 336.78 426.4 337.79 426.4 328.48 417.1 327.48 417.1 336.78"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 391.05 426.4 391.67 426.4 382.37 417.1 381.74 417.1 391.05"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 409.13 426.4 409.64 426.4 400.33 417.1 399.83 417.1 409.13"
                  />
                  <polygon
                    class="cls-1"
                    points="417.1 427.22 426.4 427.6 426.4 418.29 417.1 417.92 417.1 427.22"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="429.88 51.51 439.18 54.53 439.18 45.22 429.88 42.21 429.88 51.51"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 69.43 439.18 72.32 439.18 63.01 429.88 60.12 429.88 69.43"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 87.35 439.18 90.11 439.18 80.8 429.88 78.04 429.88 87.35"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 123.18 439.18 125.69 439.18 116.38 429.88 113.87 429.88 123.18"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 159.01 439.18 161.27 439.18 151.96 429.88 149.7 429.88 159.01"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 176.92 439.18 179.06 439.18 169.75 429.88 167.62 429.88 176.92"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 194.84 439.18 196.85 439.18 187.54 429.88 185.53 429.88 194.84"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 230.67 439.18 232.43 439.18 223.12 429.88 221.36 429.88 230.67"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 248.58 439.18 250.22 439.18 240.91 429.88 239.28 429.88 248.58"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 284.42 439.18 285.8 439.18 276.49 429.88 275.11 429.88 284.42"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 302.33 439.18 303.59 439.18 294.28 429.88 293.03 429.88 302.33"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 320.25 439.18 321.38 439.18 312.07 429.88 310.94 429.88 320.25"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 356.08 439.18 356.96 439.18 347.65 429.88 346.77 429.88 356.08"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 373.99 439.18 374.75 439.18 365.44 429.88 364.69 429.88 373.99"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 409.82 439.18 410.32 439.18 401.02 429.88 400.52 429.88 409.82"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 427.74 439.18 428.11 439.18 418.81 429.88 418.43 429.88 427.74"
                  />
                  <polygon
                    class="cls-1"
                    points="429.88 463.57 439.18 463.69 439.18 454.39 429.88 454.26 429.88 463.57"
                  />
                  <rect
                    class="cls-1"
                    x="429.88"
                    y="472.18"
                    width="9.31"
                    height="9.31"
                  />
                  <rect
                    class="cls-1"
                    x="429.88"
                    y="516.72"
                    width="9.31"
                    height="9.31"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="355.27 49.61 348.45 52.5 348.45 43.19 355.27 40.31 355.27 49.61"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 87.17 348.45 89.8 348.45 80.5 355.27 77.86 355.27 87.17"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 105.95 348.45 108.46 348.45 99.15 355.27 96.64 355.27 105.95"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 162.28 348.45 164.41 348.45 155.1 355.27 152.97 355.27 162.28"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 181.05 348.45 183.06 348.45 173.76 355.27 171.75 355.27 181.05"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 199.83 348.45 201.71 348.45 192.41 355.27 190.52 355.27 199.83"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 237.38 348.45 239.02 348.45 229.71 355.27 228.08 355.27 237.38"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 256.16 348.45 257.67 348.45 248.36 355.27 246.86 355.27 256.16"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 274.94 348.45 276.32 348.45 267.01 355.27 265.63 355.27 274.94"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 312.49 348.45 313.62 348.45 304.32 355.27 303.19 355.27 312.49"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 331.27 348.45 332.27 348.45 322.97 355.27 321.96 355.27 331.27"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 350.05 348.45 350.92 348.45 341.62 355.27 340.74 355.27 350.05"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 368.82 348.45 369.58 348.45 360.27 355.27 359.52 355.27 368.82"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 406.38 348.45 406.88 348.45 397.57 355.27 397.07 355.27 406.38"
                  />
                  <polygon
                    class="cls-1"
                    points="355.27 425.15 348.45 425.53 348.45 416.22 355.27 415.85 355.27 425.15"
                  />
                  <rect
                    class="cls-1"
                    x="348.45"
                    y="472.18"
                    width="6.82"
                    height="9.31"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="345.9 34.97 339.08 37.98 339.08 28.68 345.9 25.67 345.9 34.97"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 47.16 345.9 44.27 345.9 53.58 339.08 56.46 339.08 47.16"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 65.64 345.9 62.88 345.9 72.18 339.08 74.94 339.08 65.64"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 102.6 345.9 100.09 345.9 109.39 339.08 111.9 339.08 102.6"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 121.07 345.9 118.69 345.9 128 339.08 130.38 339.08 121.07"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 139.55 345.9 137.3 345.9 146.6 339.08 148.86 339.08 139.55"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 158.03 345.9 155.9 345.9 165.21 339.08 167.34 339.08 158.03"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 194.99 345.9 193.11 345.9 202.41 339.08 204.3 339.08 194.99"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 213.47 345.9 211.71 345.9 221.02 339.08 222.78 339.08 213.47"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 231.95 345.9 230.32 345.9 239.62 339.08 241.26 339.08 231.95"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 268.91 345.9 267.53 345.9 276.83 339.08 278.21 339.08 268.91"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 287.39 345.9 286.13 345.9 295.44 339.08 296.69 339.08 287.39"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 305.87 345.9 304.74 345.9 314.04 339.08 315.17 339.08 305.87"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 324.35 345.9 323.34 345.9 332.65 339.08 333.65 339.08 324.35"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 342.82 345.9 341.95 345.9 351.25 339.08 352.13 339.08 342.82"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 361.3 345.9 360.55 345.9 369.86 339.08 370.61 339.08 361.3"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 379.78 345.9 379.16 345.9 388.46 339.08 389.09 339.08 379.78"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 416.74 345.9 416.36 345.9 425.67 339.08 426.05 339.08 416.74"
                  />
                  <polygon
                    class="cls-1"
                    points="339.08 435.22 345.9 434.97 345.9 444.28 339.08 444.53 339.08 435.22"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="336.53 39.11 329.71 42.12 329.71 32.81 336.53 29.8 336.53 39.11"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 57.54 329.71 60.43 329.71 51.12 336.53 48.24 336.53 57.54"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 75.97 329.71 78.73 329.71 69.43 336.53 66.67 336.53 75.97"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 94.41 329.71 97.04 329.71 87.73 336.53 85.1 336.53 94.41"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 112.84 329.71 115.35 329.71 106.04 336.53 103.53 336.53 112.84"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 149.7 329.71 151.96 329.71 142.66 336.53 140.4 336.53 149.7"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 168.14 329.71 170.27 329.71 160.96 336.53 158.83 336.53 168.14"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 186.57 329.71 188.58 329.71 179.27 336.53 177.26 336.53 186.57"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 205 329.71 206.88 329.71 197.58 336.53 195.69 336.53 205"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 260.3 329.71 261.8 329.71 252.5 336.53 250.99 336.53 260.3"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 278.73 329.71 280.11 329.71 270.8 336.53 269.42 336.53 278.73"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 297.16 329.71 298.42 329.71 289.11 336.53 287.86 336.53 297.16"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 352.46 329.71 353.34 329.71 344.03 336.53 343.15 336.53 352.46"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 407.75 329.71 408.26 329.71 398.95 336.53 398.45 336.53 407.75"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 426.19 329.71 426.56 329.71 417.26 336.53 416.88 336.53 426.19"
                  />
                  <polygon
                    class="cls-1"
                    points="336.53 444.62 329.71 444.87 329.71 435.57 336.53 435.31 336.53 444.62"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="327.16 43.24 320.34 46.26 320.34 36.95 327.16 33.94 327.16 43.24"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 61.51 320.34 64.39 320.34 55.08 327.16 52.2 327.16 61.51"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 79.76 320.34 82.53 320.34 73.22 327.16 70.46 327.16 79.76"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 98.03 320.34 100.66 320.34 91.35 327.16 88.72 327.16 98.03"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 116.29 320.34 118.8 320.34 109.49 327.16 106.98 327.16 116.29"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 134.55 320.34 136.93 320.34 127.62 327.16 125.24 327.16 134.55"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 152.8 320.34 155.06 320.34 145.76 327.16 143.5 327.16 152.8"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 171.07 320.34 173.2 320.34 163.89 327.16 161.76 327.16 171.07"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 189.32 320.34 191.33 320.34 182.03 327.16 180.02 327.16 189.32"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 207.58 320.34 209.47 320.34 200.16 327.16 198.28 327.16 207.58"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 225.84 320.34 227.6 320.34 218.3 327.16 216.54 327.16 225.84"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 262.36 320.34 263.87 320.34 254.56 327.16 253.06 327.16 262.36"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 298.88 320.34 300.14 320.34 290.83 327.16 289.58 327.16 298.88"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 317.14 320.34 318.27 320.34 308.97 327.16 307.84 327.16 317.14"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 335.41 320.34 336.41 320.34 327.1 327.16 326.1 327.16 335.41"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 408.44 320.34 408.95 320.34 399.64 327.16 399.14 327.16 408.44"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 426.7 320.34 427.08 320.34 417.78 327.16 417.4 327.16 426.7"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 444.96 320.34 445.21 320.34 435.91 327.16 435.66 327.16 444.96"
                  />
                  <polygon
                    class="cls-1"
                    points="327.16 463.22 320.34 463.35 320.34 454.04 327.16 453.92 327.16 463.22"
                  />
                  <rect
                    class="cls-1"
                    x="320.34"
                    y="472.18"
                    width="6.82"
                    height="9.31"
                  />
                  <rect
                    class="cls-1"
                    x="320.34"
                    y="516.72"
                    width="6.82"
                    height="9.31"
                  />
                  <rect
                    class="cls-1"
                    x="320.34"
                    y="561.27"
                    width="6.82"
                    height="9.31"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="317.79 47.38 310.97 50.39 310.97 41.09 317.79 38.07 317.79 47.38"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 65.47 310.97 68.35 310.97 59.05 317.79 56.16 317.79 65.47"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 83.56 310.97 86.32 310.97 77.01 317.79 74.25 317.79 83.56"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 101.64 310.97 104.28 310.97 94.97 317.79 92.34 317.79 101.64"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 119.73 310.97 122.24 310.97 112.93 317.79 110.43 317.79 119.73"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 137.82 310.97 140.2 310.97 130.9 317.79 128.51 317.79 137.82"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 155.91 310.97 158.16 310.97 148.86 317.79 146.6 317.79 155.91"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 192.08 310.97 194.09 310.97 184.78 317.79 182.78 317.79 192.08"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 210.17 310.97 212.05 310.97 202.75 317.79 200.86 317.79 210.17"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 228.26 310.97 230.01 310.97 220.71 317.79 218.95 317.79 228.26"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 246.34 310.97 247.98 310.97 238.67 317.79 237.04 317.79 246.34"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 264.43 310.97 265.94 310.97 256.63 317.79 255.13 317.79 264.43"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 282.52 310.97 283.9 310.97 274.59 317.79 273.21 317.79 282.52"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 300.61 310.97 301.86 310.97 292.56 317.79 291.3 317.79 300.61"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 336.78 310.97 337.79 310.97 328.48 317.79 327.48 317.79 336.78"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 372.96 310.97 373.71 310.97 364.41 317.79 363.65 317.79 372.96"
                  />
                  <polygon
                    class="cls-1"
                    points="317.79 409.13 310.97 409.64 310.97 400.33 317.79 399.83 317.79 409.13"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="308.42 51.51 301.6 54.53 301.6 45.22 308.42 42.21 308.42 51.51"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 69.43 301.6 72.32 301.6 63.01 308.42 60.12 308.42 69.43"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 123.18 301.6 125.69 301.6 116.38 308.42 113.87 308.42 123.18"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 141.09 301.6 143.48 301.6 134.17 308.42 131.79 308.42 141.09"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 159.01 301.6 161.27 301.6 151.96 308.42 149.7 308.42 159.01"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 176.92 301.6 179.06 301.6 169.75 308.42 167.62 308.42 176.92"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 212.75 301.6 214.64 301.6 205.33 308.42 203.45 308.42 212.75"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 230.67 301.6 232.43 301.6 223.12 308.42 221.36 308.42 230.67"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 266.5 301.6 268.01 301.6 258.7 308.42 257.19 308.42 266.5"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 284.42 301.6 285.8 301.6 276.49 308.42 275.11 308.42 284.42"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 302.33 301.6 303.59 301.6 294.28 308.42 293.03 308.42 302.33"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 320.25 301.6 321.38 301.6 312.07 308.42 310.94 308.42 320.25"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 338.16 301.6 339.16 301.6 329.86 308.42 328.86 308.42 338.16"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 356.08 301.6 356.96 301.6 347.65 308.42 346.77 308.42 356.08"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 373.99 301.6 374.75 301.6 365.44 308.42 364.69 308.42 373.99"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 427.74 301.6 428.11 301.6 418.81 308.42 418.43 308.42 427.74"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 445.65 301.6 445.91 301.6 436.6 308.42 436.35 308.42 445.65"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 551.58 301.6 551.83 301.6 542.53 308.42 542.28 308.42 551.58"
                  />
                  <polygon
                    class="cls-1"
                    points="308.42 463.57 301.6 463.69 301.6 454.39 308.42 454.26 308.42 463.57"
                  />
                  <rect
                    class="cls-1"
                    x="301.6"
                    y="472.18"
                    width="6.82"
                    height="9.31"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="237.89 134.38 244.17 136.41 244.17 130.14 237.89 128.11 237.89 134.38"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 147.04 244.17 148.99 244.17 142.71 237.89 140.77 237.89 147.04"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 159.71 244.17 161.57 244.17 155.29 237.89 153.43 237.89 159.71"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 172.37 244.17 174.14 244.17 167.87 237.89 166.09 237.89 172.37"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 197.69 244.17 199.3 244.17 193.02 237.89 191.42 237.89 197.69"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 210.35 244.17 211.88 244.17 205.6 237.89 204.08 237.89 210.35"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 223.02 244.17 224.46 244.17 218.18 237.89 216.74 237.89 223.02"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 235.68 244.17 237.03 244.17 230.76 237.89 229.41 237.89 235.68"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 248.34 244.17 249.61 244.17 243.34 237.89 242.07 237.89 248.34"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 273.67 244.17 274.77 244.17 268.49 237.89 267.39 237.89 273.67"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 298.99 244.17 299.92 244.17 293.65 237.89 292.72 237.89 298.99"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 311.65 244.17 312.5 244.17 306.23 237.89 305.38 237.89 311.65"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 336.98 244.17 337.66 244.17 331.38 237.89 330.7 237.89 336.98"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 349.64 244.17 350.23 244.17 343.96 237.89 343.37 237.89 349.64"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 374.97 244.17 375.39 244.17 369.11 237.89 368.69 237.89 374.97"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 400.29 244.17 400.54 244.17 394.27 237.89 394.02 237.89 400.29"
                  />
                  <polygon
                    class="cls-1"
                    points="237.89 412.95 244.17 413.12 244.17 406.85 237.89 406.68 237.89 412.95"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="246.51 137.17 252.79 139.2 252.79 132.93 246.51 130.89 246.51 137.17"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 145.39 246.51 143.44 246.51 149.72 252.79 151.66 252.79 145.39"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 157.85 246.51 155.99 246.51 162.26 252.79 164.12 252.79 157.85"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 182.77 246.51 181.08 246.51 187.35 252.79 189.05 252.79 182.77"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 195.23 246.51 193.63 246.51 199.9 252.79 201.51 252.79 195.23"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 207.7 246.51 206.17 246.51 212.45 252.79 213.97 252.79 207.7"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 232.62 246.51 231.26 246.51 237.54 252.79 238.89 252.79 232.62"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 245.08 246.51 243.81 246.51 250.09 252.79 251.35 252.79 245.08"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 257.54 246.51 256.36 246.51 262.63 252.79 263.82 252.79 257.54"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 270 246.51 268.9 246.51 275.18 252.79 276.28 252.79 270"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 282.46 246.51 281.45 246.51 287.72 252.79 288.74 252.79 282.46"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 307.39 246.51 306.54 246.51 312.82 252.79 313.66 252.79 307.39"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 319.85 246.51 319.09 246.51 325.36 252.79 326.12 252.79 319.85"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 332.31 246.51 331.63 246.51 337.91 252.79 338.59 252.79 332.31"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 344.77 246.51 344.18 246.51 350.45 252.79 351.05 252.79 344.77"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 369.7 246.51 369.27 246.51 375.55 252.79 375.97 252.79 369.7"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 382.16 246.51 381.82 246.51 388.09 252.79 388.43 252.79 382.16"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 394.62 246.51 394.36 246.51 400.64 252.79 400.89 252.79 394.62"
                  />
                  <polygon
                    class="cls-1"
                    points="252.79 419.54 246.51 419.46 246.51 425.73 252.79 425.82 252.79 419.54"
                  />
                  <rect
                    class="cls-1"
                    x="246.51"
                    y="432"
                    width="6.28"
                    height="6.28"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="255.13 152.39 261.4 154.33 261.4 148.06 255.13 146.11 255.13 152.39"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 177.25 261.4 179.03 261.4 172.75 255.13 170.97 255.13 177.25"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 189.68 261.4 191.37 261.4 185.1 255.13 183.4 255.13 189.68"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 202.11 261.4 203.72 261.4 197.44 255.13 195.83 255.13 202.11"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 214.54 261.4 216.06 261.4 209.79 255.13 208.26 255.13 214.54"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 226.97 261.4 228.41 261.4 222.13 255.13 220.69 255.13 226.97"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 239.4 261.4 240.75 261.4 234.48 255.13 233.12 255.13 239.4"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 251.83 261.4 253.1 261.4 246.82 255.13 245.55 255.13 251.83"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 264.26 261.4 265.44 261.4 259.17 255.13 257.98 255.13 264.26"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 276.69 261.4 277.79 261.4 271.51 255.13 270.41 255.13 276.69"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 289.12 261.4 290.13 261.4 283.86 255.13 282.84 255.13 289.12"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 313.98 261.4 314.82 261.4 308.55 255.13 307.7 255.13 313.98"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 326.41 261.4 327.17 261.4 320.89 255.13 320.13 255.13 326.41"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 338.84 261.4 339.52 261.4 333.24 255.13 332.56 255.13 338.84"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 351.27 261.4 351.86 261.4 345.59 255.13 344.99 255.13 351.27"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 363.7 261.4 364.21 261.4 357.93 255.13 357.42 255.13 363.7"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 376.13 261.4 376.55 261.4 370.28 255.13 369.85 255.13 376.13"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 388.56 261.4 388.9 261.4 382.62 255.13 382.28 255.13 388.56"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 400.99 261.4 401.24 261.4 394.97 255.13 394.71 255.13 400.99"
                  />
                  <polygon
                    class="cls-1"
                    points="255.13 413.42 261.4 413.59 261.4 407.31 255.13 407.14 255.13 413.42"
                  />
                  <rect
                    class="cls-1"
                    x="255.13"
                    y="432"
                    width="6.28"
                    height="6.28"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="263.75 142.75 270.02 144.78 270.02 138.5 263.75 136.47 263.75 142.75"
                  />
                  <polygon
                    class="cls-1"
                    points="263.74 167.38 270.02 169.24 270.02 162.96 263.74 161.1 263.74 167.38"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 179.69 270.02 181.47 270.02 175.19 263.75 173.41 263.75 179.69"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 216.63 270.02 218.15 270.02 211.88 263.75 210.35 263.75 216.63"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 228.95 270.02 230.38 270.02 224.11 263.75 222.67 263.75 228.95"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 241.26 270.02 242.61 270.02 236.34 263.75 234.98 263.75 241.26"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 265.89 270.02 267.07 270.02 260.8 263.75 259.61 263.75 265.89"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 278.2 270.02 279.3 270.02 273.02 263.75 271.92 263.75 278.2"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 290.51 270.02 291.53 270.02 285.25 263.75 284.24 263.75 290.51"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 302.83 270.02 303.76 270.02 297.48 263.75 296.55 263.75 302.83"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 315.14 270.02 315.99 270.02 309.71 263.75 308.86 263.75 315.14"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 327.45 270.02 328.22 270.02 321.94 263.75 321.18 263.75 327.45"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 352.08 270.02 352.67 270.02 346.4 263.75 345.81 263.75 352.08"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 364.4 270.02 364.9 270.02 358.63 263.75 358.12 263.75 364.4"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 376.71 270.02 377.13 270.02 370.86 263.75 370.43 263.75 376.71"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 401.34 270.02 401.59 270.02 395.32 263.75 395.06 263.75 401.34"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 413.65 270.02 413.82 270.02 407.54 263.75 407.37 263.75 413.65"
                  />
                  <polygon
                    class="cls-1"
                    points="263.75 425.96 270.02 426.05 270.02 419.77 263.75 419.69 263.75 425.96"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="272.36 145.54 278.64 147.57 278.64 141.29 272.36 139.26 272.36 145.54"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 157.73 278.64 159.68 278.64 153.41 272.36 151.46 272.36 157.73"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 169.93 278.64 171.79 278.64 165.52 272.36 163.66 272.36 169.93"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 182.13 278.64 183.91 278.64 177.63 272.36 175.85 272.36 182.13"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 194.33 278.64 196.02 278.64 189.74 272.36 188.05 272.36 194.33"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 206.52 278.64 208.13 278.64 201.86 272.36 200.25 272.36 206.52"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 230.92 278.64 232.36 278.64 226.08 272.36 224.64 272.36 230.92"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 255.31 278.64 256.58 278.64 250.31 272.36 249.04 272.36 255.31"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 267.51 278.64 268.7 278.64 262.42 272.36 261.24 272.36 267.51"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 291.91 278.64 292.92 278.64 286.65 272.36 285.63 272.36 291.91"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 304.11 278.64 305.04 278.64 298.76 272.36 297.83 272.36 304.11"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 316.3 278.64 317.15 278.64 310.87 272.36 310.03 272.36 316.3"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 328.5 278.64 329.26 278.64 322.99 272.36 322.22 272.36 328.5"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 340.7 278.64 341.37 278.64 335.1 272.36 334.42 272.36 340.7"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 365.09 278.64 365.6 278.64 359.33 272.36 358.82 272.36 365.09"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 389.49 278.64 389.83 278.64 383.55 272.36 383.21 272.36 389.49"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 401.69 278.64 401.94 278.64 395.66 272.36 395.41 272.36 401.69"
                  />
                  <polygon
                    class="cls-1"
                    points="272.36 426.08 278.64 426.17 278.64 419.89 272.36 419.81 272.36 426.08"
                  />
                  <rect
                    class="cls-1"
                    x="272.36"
                    y="432"
                    width="6.28"
                    height="6.28"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="280.98 148.33 287.26 150.36 287.26 144.08 280.98 142.05 280.98 148.33"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 160.41 287.26 162.35 287.26 156.08 280.98 154.13 280.98 160.41"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 184.57 287.26 186.35 287.26 180.07 280.98 178.29 280.98 184.57"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 196.65 287.26 198.34 287.26 192.07 280.98 190.38 280.98 196.65"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 208.73 287.26 210.34 287.26 204.07 280.98 202.46 280.98 208.73"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 220.81 287.26 222.34 287.26 216.06 280.98 214.54 280.98 220.81"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 232.89 287.26 234.33 287.26 228.06 280.98 226.62 280.98 232.89"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 244.98 287.26 246.33 287.26 240.06 280.98 238.7 280.98 244.98"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 257.06 287.26 258.33 287.26 252.05 280.98 250.78 280.98 257.06"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 269.14 287.26 270.32 287.26 264.05 280.98 262.86 280.98 269.14"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 281.22 287.26 282.32 287.26 276.05 280.98 274.95 280.98 281.22"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 293.3 287.26 294.32 287.26 288.04 280.98 287.03 280.98 293.3"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 305.38 287.26 306.31 287.26 300.04 280.98 299.11 280.98 305.38"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 329.55 287.26 330.31 287.26 324.03 280.98 323.27 280.98 329.55"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 341.63 287.26 342.3 287.26 336.03 280.98 335.35 280.98 341.63"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 353.71 287.26 354.3 287.26 348.03 280.98 347.43 280.98 353.71"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 389.95 287.26 390.29 287.26 384.02 280.98 383.68 280.98 389.95"
                  />
                  <polygon
                    class="cls-1"
                    points="280.98 426.2 287.26 426.28 287.26 420.01 280.98 419.92 280.98 426.2"
                  />
                  <rect
                    class="cls-1"
                    x="280.98"
                    y="432"
                    width="6.28"
                    height="6.28"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="230.67 134.38 226.07 136.41 226.07 130.14 230.67 128.11 230.67 134.38"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 147.04 226.07 148.99 226.07 142.71 230.67 140.77 230.67 147.04"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 159.71 226.07 161.57 226.07 155.29 230.67 153.43 230.67 159.71"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 172.37 226.07 174.14 226.07 167.87 230.67 166.09 230.67 172.37"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 185.03 226.07 186.72 226.07 180.45 230.67 178.76 230.67 185.03"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 197.69 226.07 199.3 226.07 193.02 230.67 191.42 230.67 197.69"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 210.35 226.07 211.88 226.07 205.6 230.67 204.08 230.67 210.35"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 223.02 226.07 224.46 226.07 218.18 230.67 216.74 230.67 223.02"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 273.67 226.07 274.77 226.07 268.49 230.67 267.39 230.67 273.67"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 298.99 226.07 299.92 226.07 293.65 230.67 292.72 230.67 298.99"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 311.65 226.07 312.5 226.07 306.23 230.67 305.38 230.67 311.65"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 324.32 226.07 325.08 226.07 318.8 230.67 318.04 230.67 324.32"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 336.98 226.07 337.66 226.07 331.38 230.67 330.7 230.67 336.98"
                  />
                  <polygon
                    class="cls-1"
                    points="230.67 349.64 226.07 350.23 226.07 343.96 230.67 343.37 230.67 349.64"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="219.75 157.85 224.35 155.99 224.35 162.26 219.75 164.12 219.75 157.85"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 170.31 224.35 168.53 224.35 174.81 219.75 176.59 219.75 170.31"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 182.77 224.35 181.08 224.35 187.35 219.75 189.05 219.75 182.77"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 195.23 224.35 193.63 224.35 199.9 219.75 201.51 219.75 195.23"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 207.7 224.35 206.17 224.35 212.45 219.75 213.97 219.75 207.7"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 232.62 224.35 231.26 224.35 237.54 219.75 238.89 219.75 232.62"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 245.08 224.35 243.81 224.35 250.09 219.75 251.35 219.75 245.08"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 257.54 224.35 256.36 224.35 262.63 219.75 263.82 219.75 257.54"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 270 224.35 268.9 224.35 275.18 219.75 276.28 219.75 270"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 282.46 224.35 281.45 224.35 287.72 219.75 288.74 219.75 282.46"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 294.93 224.35 293.99 224.35 300.27 219.75 301.2 219.75 294.93"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 307.39 224.35 306.54 224.35 312.82 219.75 313.66 219.75 307.39"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 319.85 224.35 319.09 224.35 325.36 219.75 326.12 219.75 319.85"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 332.31 224.35 331.63 224.35 337.91 219.75 338.59 219.75 332.31"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 344.77 224.35 344.18 224.35 350.45 219.75 351.05 219.75 344.77"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 357.23 224.35 356.73 224.35 363 219.75 363.51 219.75 357.23"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 369.7 224.35 369.27 224.35 375.55 219.75 375.97 219.75 369.7"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 382.16 224.35 381.82 224.35 388.09 219.75 388.43 219.75 382.16"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 394.62 224.35 394.36 224.35 400.64 219.75 400.89 219.75 394.62"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 407.08 224.35 406.91 224.35 413.19 219.75 413.35 219.75 407.08"
                  />
                  <polygon
                    class="cls-1"
                    points="219.75 419.54 224.35 419.46 224.35 425.73 219.75 425.82 219.75 419.54"
                  />
                  <rect
                    class="cls-1"
                    x="219.75"
                    y="432"
                    width="4.6"
                    height="6.28"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="218.03 152.39 213.43 154.33 213.43 148.06 218.03 146.11 218.03 152.39"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 177.25 213.43 179.03 213.43 172.75 218.03 170.97 218.03 177.25"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 189.68 213.43 191.37 213.43 185.1 218.03 183.4 218.03 189.68"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 214.54 213.43 216.06 213.43 209.79 218.03 208.26 218.03 214.54"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 226.97 213.43 228.41 213.43 222.13 218.03 220.69 218.03 226.97"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 239.4 213.43 240.75 213.43 234.48 218.03 233.12 218.03 239.4"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 251.83 213.43 253.1 213.43 246.82 218.03 245.55 218.03 251.83"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 264.26 213.43 265.44 213.43 259.17 218.03 257.98 218.03 264.26"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 276.69 213.43 277.79 213.43 271.51 218.03 270.41 218.03 276.69"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 301.55 213.43 302.48 213.43 296.2 218.03 295.27 218.03 301.55"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 326.41 213.43 327.17 213.43 320.89 218.03 320.13 218.03 326.41"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 338.84 213.43 339.52 213.43 333.24 218.03 332.56 218.03 338.84"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 363.7 213.43 364.21 213.43 357.93 218.03 357.42 218.03 363.7"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 376.13 213.43 376.55 213.43 370.28 218.03 369.85 218.03 376.13"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 388.56 213.43 388.9 213.43 382.62 218.03 382.28 218.03 388.56"
                  />
                  <polygon
                    class="cls-1"
                    points="218.03 400.99 213.43 401.24 213.43 394.97 218.03 394.71 218.03 400.99"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="211.71 142.75 207.11 144.78 207.11 138.5 211.71 136.47 211.71 142.75"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 155.06 207.11 157.01 207.11 150.73 211.71 148.79 211.71 155.06"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 167.38 207.11 169.24 207.11 162.96 211.71 161.1 211.71 167.38"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 192 207.11 193.7 207.11 187.42 211.71 185.73 211.71 192"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 204.32 207.11 205.92 207.11 199.65 211.71 198.04 211.71 204.32"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 228.95 207.11 230.38 207.11 224.11 211.71 222.67 211.71 228.95"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 265.89 207.11 267.07 207.11 260.8 211.71 259.61 211.71 265.89"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 278.2 207.11 279.3 207.11 273.02 211.71 271.92 211.71 278.2"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 290.51 207.11 291.53 207.11 285.25 211.71 284.24 211.71 290.51"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 302.83 207.11 303.76 207.11 297.48 211.71 296.55 211.71 302.83"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 315.14 207.11 315.99 207.11 309.71 211.71 308.86 211.71 315.14"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 339.77 207.11 340.45 207.11 334.17 211.71 333.49 211.71 339.77"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 352.08 207.11 352.67 207.11 346.4 211.71 345.81 211.71 352.08"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 364.4 207.11 364.9 207.11 358.63 211.71 358.12 211.71 364.4"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 389.02 207.11 389.36 207.11 383.09 211.71 382.75 211.71 389.02"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 401.34 207.11 401.59 207.11 395.32 211.71 395.06 211.71 401.34"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 413.65 207.11 413.82 207.11 407.54 211.71 407.37 211.71 413.65"
                  />
                  <polygon
                    class="cls-1"
                    points="211.71 425.96 207.11 426.05 207.11 419.77 211.71 419.69 211.71 425.96"
                  />
                  <rect
                    class="cls-1"
                    x="207.11"
                    y="432"
                    width="4.6"
                    height="6.28"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="205.39 157.73 200.79 159.68 200.79 153.41 205.39 151.46 205.39 157.73"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 169.93 200.79 171.79 200.79 165.52 205.39 163.66 205.39 169.93"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 182.13 200.79 183.91 200.79 177.63 205.39 175.85 205.39 182.13"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 194.33 200.79 196.02 200.79 189.74 205.39 188.05 205.39 194.33"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 206.52 200.79 208.13 200.79 201.86 205.39 200.25 205.39 206.52"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 218.72 200.79 220.24 200.79 213.97 205.39 212.45 205.39 218.72"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 230.92 200.79 232.36 200.79 226.08 205.39 224.64 205.39 230.92"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 243.12 200.79 244.47 200.79 238.2 205.39 236.84 205.39 243.12"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 255.31 200.79 256.58 200.79 250.31 205.39 249.04 205.39 255.31"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 279.71 200.79 280.81 200.79 274.53 205.39 273.43 205.39 279.71"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 291.91 200.79 292.92 200.79 286.65 205.39 285.63 205.39 291.91"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 304.11 200.79 305.04 200.79 298.76 205.39 297.83 205.39 304.11"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 316.3 200.79 317.15 200.79 310.87 205.39 310.03 205.39 316.3"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 328.5 200.79 329.26 200.79 322.99 205.39 322.22 205.39 328.5"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 340.7 200.79 341.37 200.79 335.1 205.39 334.42 205.39 340.7"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 365.09 200.79 365.6 200.79 359.33 205.39 358.82 205.39 365.09"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 377.29 200.79 377.71 200.79 371.44 205.39 371.02 205.39 377.29"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 389.49 200.79 389.83 200.79 383.55 205.39 383.21 205.39 389.49"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 401.69 200.79 401.94 200.79 395.66 205.39 395.41 205.39 401.69"
                  />
                  <polygon
                    class="cls-1"
                    points="205.39 413.88 200.79 414.05 200.79 407.78 205.39 407.61 205.39 413.88"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="199.07 148.33 194.47 150.36 194.47 144.08 199.07 142.05 199.07 148.33"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 184.57 194.47 186.35 194.47 180.07 199.07 178.29 199.07 184.57"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 196.65 194.47 198.34 194.47 192.07 199.07 190.38 199.07 196.65"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 208.73 194.47 210.34 194.47 204.07 199.07 202.46 199.07 208.73"
                  />
                  <polygon
                    class="cls-1"
                    points="199.08 220.81 194.47 222.34 194.47 216.06 199.08 214.54 199.08 220.81"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 244.98 194.47 246.33 194.47 240.06 199.07 238.7 199.07 244.98"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 257.06 194.47 258.33 194.47 252.05 199.07 250.78 199.07 257.06"
                  />
                  <polygon
                    class="cls-1"
                    points="199.08 269.14 194.47 270.32 194.47 264.05 199.08 262.86 199.08 269.14"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 305.38 194.47 306.31 194.47 300.04 199.07 299.11 199.07 305.38"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 317.46 194.47 318.31 194.47 312.04 199.07 311.19 199.07 317.46"
                  />
                  <polygon
                    class="cls-1"
                    points="199.08 353.71 194.47 354.3 194.47 348.03 199.08 347.43 199.08 353.71"
                  />
                  <polygon
                    class="cls-1"
                    points="199.08 365.79 194.47 366.3 194.47 360.02 199.08 359.52 199.08 365.79"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 377.87 194.47 378.29 194.47 372.02 199.07 371.6 199.07 377.87"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 389.95 194.47 390.29 194.47 384.02 199.07 383.68 199.07 389.95"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 402.03 194.47 402.29 194.47 396.01 199.07 395.76 199.07 402.03"
                  />
                  <polygon
                    class="cls-1"
                    points="199.07 414.11 194.47 414.28 194.47 408.01 199.07 407.84 199.07 414.11"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="485.11 176.78 489.02 178.5 489.02 173.17 485.11 171.44 485.11 176.78"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 187.54 489.02 189.2 489.02 183.86 485.11 182.21 485.11 187.54"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 198.31 489.02 199.89 489.02 194.56 485.11 192.97 485.11 198.31"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 209.07 489.02 210.58 489.02 205.25 485.11 203.74 485.11 209.07"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 219.84 489.02 221.28 489.02 215.94 485.11 214.5 485.11 219.84"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 230.6 489.02 231.97 489.02 226.63 485.11 225.27 485.11 230.6"
                  />
                  <polygon
                    class="cls-1"
                    points="485.1 241.37 489.02 242.66 489.02 237.33 485.1 236.03 485.1 241.37"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 252.13 489.02 253.36 489.02 248.02 485.11 246.8 485.11 252.13"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 284.43 489.02 285.44 489.02 280.1 485.11 279.09 485.11 284.43"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 295.19 489.02 296.13 489.02 290.79 485.11 289.86 485.11 295.19"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 305.96 489.02 306.82 489.02 301.49 485.11 300.62 485.11 305.96"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 316.72 489.02 317.52 489.02 312.18 485.11 311.39 485.11 316.72"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 338.25 489.02 338.9 489.02 333.57 485.11 332.92 485.11 338.25"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 349.02 489.02 349.59 489.02 344.26 485.11 343.68 485.11 349.02"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 381.31 489.02 381.67 489.02 376.34 485.11 375.98 485.11 381.31"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 402.84 489.02 403.06 489.02 397.73 485.11 397.51 485.11 402.84"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 413.61 489.02 413.75 489.02 408.42 485.11 408.28 485.11 413.61"
                  />
                  <polygon
                    class="cls-1"
                    points="485.11 424.37 489.02 424.45 489.02 419.11 485.11 419.04 485.11 424.37"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="490.48 179.15 494.39 180.88 494.39 175.54 490.48 173.81 490.48 179.15"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 196.73 490.48 195.15 490.48 200.48 494.39 202.06 494.39 196.73"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 207.32 490.48 205.81 490.48 211.15 494.39 212.66 494.39 207.32"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 228.51 490.48 227.15 490.48 232.48 494.39 233.85 494.39 228.51"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 239.11 490.48 237.81 490.48 243.15 494.39 244.44 494.39 239.11"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 249.7 490.48 248.48 490.48 253.81 494.39 255.04 494.39 249.7"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 260.3 490.48 259.15 490.48 264.48 494.39 265.63 494.39 260.3"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 270.89 490.48 269.81 490.48 275.15 494.39 276.22 494.39 270.89"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 292.08 490.48 291.14 490.48 296.48 494.39 297.41 494.39 292.08"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 302.67 490.48 301.81 490.48 307.15 494.39 308.01 494.39 302.67"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 323.86 490.48 323.14 490.48 328.48 494.39 329.2 494.39 323.86"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 334.46 490.48 333.81 490.48 339.14 494.39 339.79 494.39 334.46"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 345.05 490.48 344.47 490.48 349.81 494.39 350.39 494.39 345.05"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 355.64 490.48 355.14 490.48 360.48 494.39 360.98 494.39 355.64"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 366.24 490.48 365.81 490.48 371.14 494.39 371.57 494.39 366.24"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 376.83 490.48 376.47 490.48 381.81 494.39 382.17 494.39 376.83"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 387.43 490.48 387.14 490.48 392.48 494.39 392.76 494.39 387.43"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 408.62 490.48 408.47 490.48 413.81 494.39 413.95 494.39 408.62"
                  />
                  <polygon
                    class="cls-1"
                    points="494.39 419.21 490.48 419.14 490.48 424.47 494.39 424.55 494.39 419.21"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="495.85 192.09 499.76 193.74 499.76 188.41 495.85 186.75 495.85 192.09"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 202.65 499.76 204.24 499.76 198.9 495.85 197.32 495.85 202.65"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 213.22 499.76 214.73 499.76 209.4 495.85 207.89 495.85 213.22"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 223.79 499.76 225.23 499.76 219.89 495.85 218.46 495.85 223.79"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 244.92 499.76 246.22 499.76 240.88 495.85 239.59 495.85 244.92"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 255.49 499.76 256.72 499.76 251.38 495.85 250.16 495.85 255.49"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 266.06 499.76 267.21 499.76 261.88 495.85 260.73 495.85 266.06"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 276.63 499.76 277.71 499.76 272.37 495.85 271.29 495.85 276.63"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 287.2 499.76 288.2 499.76 282.87 495.85 281.86 495.85 287.2"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 297.76 499.76 298.7 499.76 293.36 495.85 292.43 495.85 297.76"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 318.9 499.76 319.69 499.76 314.35 495.85 313.56 495.85 318.9"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 329.47 499.76 330.19 499.76 324.85 495.85 324.13 495.85 329.47"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 340.03 499.76 340.68 499.76 335.35 495.85 334.7 495.85 340.03"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 361.17 499.76 361.67 499.76 356.34 495.85 355.83 495.85 361.17"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 371.74 499.76 372.17 499.76 366.83 495.85 366.4 495.85 371.74"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 392.87 499.76 393.16 499.76 387.82 495.85 387.53 495.85 392.87"
                  />
                  <polygon
                    class="cls-1"
                    points="495.85 403.44 499.76 403.65 499.76 398.32 495.85 398.1 495.85 403.44"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="501.22 183.89 505.13 185.62 505.13 180.28 501.22 178.56 501.22 183.89"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 215.3 505.13 216.81 505.13 211.47 501.22 209.96 501.22 215.3"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 225.77 505.13 227.21 505.13 221.87 501.22 220.43 501.22 225.77"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 246.7 505.13 248 505.13 242.66 501.22 241.37 501.22 246.7"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 278.11 505.13 279.19 505.13 273.85 501.22 272.77 501.22 278.11"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 299.05 505.13 299.98 505.13 294.65 501.22 293.71 501.22 299.05"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 309.52 505.13 310.38 505.13 305.04 501.22 304.18 501.22 309.52"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 319.99 505.13 320.78 505.13 315.44 501.22 314.65 501.22 319.99"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 330.45 505.13 331.17 505.13 325.84 501.22 325.12 501.22 330.45"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 351.39 505.13 351.97 505.13 346.63 501.22 346.06 501.22 351.39"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 372.33 505.13 372.76 505.13 367.42 501.22 366.99 501.22 372.33"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 382.8 505.13 383.16 505.13 377.82 501.22 377.46 501.22 382.8"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 393.27 505.13 393.55 505.13 388.22 501.22 387.93 501.22 393.27"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 403.73 505.13 403.95 505.13 398.61 501.22 398.4 501.22 403.73"
                  />
                  <polygon
                    class="cls-1"
                    points="501.22 414.2 505.13 414.35 505.13 409.01 501.22 408.87 501.22 414.2"
                  />
                  <rect
                    class="cls-1"
                    x="501.22"
                    y="429.81"
                    width="3.91"
                    height="5.33"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="506.59 186.26 510.51 187.99 510.51 182.65 506.59 180.93 506.59 186.26"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 196.63 510.51 198.29 510.51 192.95 506.59 191.3 506.59 196.63"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 207 510.51 208.58 510.51 203.25 506.59 201.67 506.59 207"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 227.74 510.51 229.18 510.51 223.85 506.59 222.41 506.59 227.74"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 238.11 510.51 239.48 510.51 234.14 506.59 232.78 506.59 238.11"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 258.85 510.51 260.07 510.51 254.74 506.59 253.52 506.59 258.85"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 269.22 510.51 270.37 510.51 265.04 506.59 263.89 506.59 269.22"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 289.96 510.51 290.97 510.51 285.63 506.59 284.63 506.59 289.96"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 310.7 510.51 311.56 510.51 306.23 506.59 305.37 506.59 310.7"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 331.44 510.51 332.16 510.51 326.83 506.59 326.11 506.59 331.44"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 341.81 510.51 342.46 510.51 337.12 506.59 336.48 506.59 341.81"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 352.18 510.51 352.76 510.51 347.42 506.59 346.85 506.59 352.18"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 383.29 510.51 383.65 510.51 378.32 506.59 377.95 506.59 383.29"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 414.4 510.51 414.54 510.51 409.21 506.59 409.07 506.59 414.4"
                  />
                  <polygon
                    class="cls-1"
                    points="506.59 424.77 510.51 424.84 510.51 419.51 506.59 419.44 506.59 424.77"
                  />
                  <rect
                    class="cls-1"
                    x="506.59"
                    y="429.81"
                    width="3.91"
                    height="5.33"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="511.97 188.63 515.88 190.36 515.88 185.03 511.97 183.3 511.97 188.63"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 198.9 515.88 200.56 515.88 195.22 511.97 193.57 511.97 198.9"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 209.17 515.88 210.76 515.88 205.42 511.97 203.84 511.97 209.17"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 219.45 515.88 220.96 515.88 215.62 511.97 214.11 511.97 219.45"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 229.72 515.88 231.16 515.88 225.82 511.97 224.38 511.97 229.72"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 239.99 515.88 241.36 515.88 236.02 511.97 234.65 511.97 239.99"
                  />
                  <polygon
                    class="cls-1"
                    points="511.96 250.26 515.88 251.55 515.88 246.22 511.96 244.93 511.96 250.26"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 260.53 515.88 261.75 515.88 256.42 511.97 255.2 511.97 260.53"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 270.8 515.88 271.95 515.88 266.62 511.97 265.47 511.97 270.8"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 281.07 515.88 282.15 515.88 276.82 511.97 275.74 511.97 281.07"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 291.34 515.88 292.35 515.88 287.02 511.97 286.01 511.97 291.34"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 301.62 515.88 302.55 515.88 297.22 511.97 296.28 511.97 301.62"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 342.7 515.88 343.35 515.88 338.01 511.97 337.36 511.97 342.7"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 352.97 515.88 353.55 515.88 348.21 511.97 347.64 511.97 352.97"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 363.24 515.88 363.75 515.88 358.41 511.97 357.91 511.97 363.24"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 373.51 515.88 373.95 515.88 368.61 511.97 368.18 511.97 373.51"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 383.78 515.88 384.14 515.88 378.81 511.97 378.45 511.97 383.78"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 394.06 515.88 394.34 515.88 389.01 511.97 388.72 511.97 394.06"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 404.33 515.88 404.54 515.88 399.21 511.97 398.99 511.97 404.33"
                  />
                  <polygon
                    class="cls-1"
                    points="511.97 414.6 515.88 414.74 515.88 409.41 511.97 409.26 511.97 414.6"
                  />
                </g>
                <path
                  class="cls-1"
                  d="M443.12,39.25c.87,0,1.68-.54,1.99-1.41,.39-1.1-.19-2.3-1.29-2.69l-77.14-27.07c-1.1-.38-2.3,.19-2.69,1.29s.19,2.3,1.29,2.69l77.14,27.07c.23,.08,.47,.12,.7,.12Z"
                />
                <path
                  class="cls-1"
                  d="M446.48,31.91c.88,0,1.69-.55,1.99-1.42,.38-1.1-.21-2.3-1.31-2.68L366.67,.13c-1.1-.38-2.3,.21-2.68,1.31-.38,1.1,.21,2.3,1.31,2.68l80.5,27.68c.23,.08,.46,.12,.69,.12h-.01Z"
                />
                <path
                  class="cls-1"
                  d="M298.23,41.28c.31,0,.62-.07,.92-.21l59.97-29.09c1.05-.51,1.48-1.77,.98-2.82-.51-1.05-1.77-1.48-2.82-.98l-59.97,29.09c-1.05,.51-1.48,1.77-.98,2.82,.36,.75,1.12,1.19,1.9,1.19Z"
                />
                <path
                  class="cls-1"
                  d="M296.46,33.93c.31,0,.62-.07,.91-.21l61.74-29.71c1.05-.5,1.49-1.76,.99-2.81s-1.77-1.49-2.81-.99l-61.74,29.71c-1.05,.5-1.49,1.76-.99,2.81,.36,.75,1.12,1.19,1.9,1.19h0Z"
                />
                <g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="89.15 267.91 86.3 268.83 86.3 265.98 89.15 265.06 89.15 267.91"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 279.41 86.3 280.26 86.3 277.41 89.15 276.56 89.15 279.41"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 285.16 86.3 285.97 86.3 283.12 89.15 282.31 89.15 285.16"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 290.92 86.3 291.69 86.3 288.83 89.15 288.07 89.15 290.92"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 296.67 86.3 297.4 86.3 294.55 89.15 293.82 89.15 296.67"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 308.17 86.3 308.82 86.3 305.97 89.15 305.32 89.15 308.17"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 313.92 86.3 314.54 86.3 311.69 89.15 311.07 89.15 313.92"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 319.67 86.3 320.25 86.3 317.4 89.15 316.82 89.15 319.67"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 325.42 86.3 325.96 86.3 323.11 89.15 322.57 89.15 325.42"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 331.18 86.3 331.68 86.3 328.82 89.15 328.33 89.15 331.18"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 336.93 86.3 337.39 86.3 334.54 89.15 334.08 89.15 336.93"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 342.68 86.3 343.1 86.3 340.25 89.15 339.83 89.15 342.68"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 348.43 86.3 348.81 86.3 345.96 89.15 345.58 89.15 348.43"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 354.18 86.3 354.53 86.3 351.68 89.15 351.33 89.15 354.18"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 359.93 86.3 360.24 86.3 357.39 89.15 357.08 89.15 359.93"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 365.69 86.3 365.95 86.3 363.1 89.15 362.83 89.15 365.69"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 382.94 86.3 383.09 86.3 380.24 89.15 380.09 89.15 382.94"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 388.69 86.3 388.81 86.3 385.96 89.15 385.84 89.15 388.69"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 400.19 86.3 400.23 86.3 397.38 89.15 397.34 89.15 400.19"
                    />
                    <polygon
                      class="cls-1"
                      points="89.15 430.34 86.3 430.38 86.3 427.53 89.15 427.49 89.15 430.34"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="85.24 269.18 82.39 270.1 82.39 267.25 85.24 266.33 85.24 269.18"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 272.91 85.24 272.03 85.24 274.87 82.39 275.76 82.39 272.91"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 278.57 85.24 277.72 85.24 280.57 82.39 281.42 82.39 278.57"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 284.23 85.24 283.42 85.24 286.27 82.39 287.08 82.39 284.23"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 289.89 85.24 289.12 85.24 291.97 82.39 292.74 82.39 289.89"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 301.21 85.24 300.52 85.24 303.37 82.39 304.06 82.39 301.21"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 306.87 85.24 306.22 85.24 309.07 82.39 309.72 82.39 306.87"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 312.53 85.24 311.92 85.24 314.77 82.39 315.38 82.39 312.53"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 323.85 85.24 323.31 85.24 326.16 82.39 326.7 82.39 323.85"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 335.17 85.24 334.71 85.24 337.56 82.39 338.02 82.39 335.17"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 340.83 85.24 340.41 85.24 343.26 82.39 343.68 82.39 340.83"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 346.49 85.24 346.11 85.24 348.96 82.39 349.34 82.39 346.49"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 352.15 85.24 351.81 85.24 354.66 82.39 355 82.39 352.15"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 363.47 85.24 363.2 85.24 366.05 82.39 366.32 82.39 363.47"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 369.13 85.24 368.9 85.24 371.75 82.39 371.98 82.39 369.13"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 386.11 85.24 386 85.24 388.85 82.39 388.96 82.39 386.11"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 391.77 85.24 391.7 85.24 394.55 82.39 394.62 82.39 391.77"
                    />
                    <polygon
                      class="cls-1"
                      points="82.39 397.43 85.24 397.4 85.24 400.25 82.39 400.28 82.39 397.43"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="81.33 287.38 78.48 288.19 78.48 285.34 81.33 284.53 81.33 287.38"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 293.03 78.48 293.8 78.48 290.95 81.33 290.18 81.33 293.03"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 304.32 78.48 305.01 78.48 302.16 81.33 301.47 81.33 304.32"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 309.96 78.48 310.62 78.48 307.77 81.33 307.11 81.33 309.96"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 321.26 78.48 321.83 78.48 318.98 81.33 318.41 81.33 321.26"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 326.9 78.48 327.44 78.48 324.59 81.33 324.05 81.33 326.9"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 332.55 78.48 333.05 78.48 330.2 81.33 329.7 81.33 332.55"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 349.49 78.48 349.87 78.48 347.02 81.33 346.64 81.33 349.49"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 355.13 78.48 355.48 78.48 352.63 81.33 352.28 81.33 355.13"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 360.78 78.48 361.08 78.48 358.24 81.33 357.93 81.33 360.78"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 366.42 78.48 366.69 78.48 363.84 81.33 363.57 81.33 366.42"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 372.07 78.48 372.3 78.48 369.45 81.33 369.22 81.33 372.07"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 377.72 78.48 377.91 78.48 375.06 81.33 374.86 81.33 377.72"
                    />
                    <polygon
                      class="cls-1"
                      points="81.33 389.01 78.48 389.12 78.48 386.27 81.33 386.16 81.33 389.01"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="77.41 277.3 74.56 278.19 74.56 275.34 77.41 274.45 77.41 277.3"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 282.9 74.56 283.74 74.56 280.89 77.41 280.05 77.41 282.9"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 294.08 74.56 294.85 74.56 292 77.41 291.23 77.41 294.08"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 299.68 74.56 300.41 74.56 297.55 77.41 296.83 77.41 299.68"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 305.27 74.56 305.96 74.56 303.11 77.41 302.42 77.41 305.27"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 316.45 74.56 317.07 74.56 314.22 77.41 313.61 77.41 316.45"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 322.05 74.56 322.62 74.56 319.78 77.41 319.2 77.41 322.05"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 338.83 74.56 339.29 74.56 336.44 77.41 335.98 77.41 338.83"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 344.42 74.56 344.84 74.56 341.99 77.41 341.57 77.41 344.42"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 350.01 74.56 350.4 74.56 347.55 77.41 347.16 77.41 350.01"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 355.61 74.56 355.95 74.56 353.1 77.41 352.76 77.41 355.61"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 361.2 74.56 361.51 74.56 358.66 77.41 358.35 77.41 361.2"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 366.79 74.56 367.06 74.56 364.21 77.41 363.94 77.41 366.79"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 377.98 74.56 378.17 74.56 375.32 77.41 375.13 77.41 377.98"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 394.76 74.56 394.84 74.56 391.99 77.41 391.91 77.41 394.76"
                    />
                    <polygon
                      class="cls-1"
                      points="77.41 400.35 74.56 400.39 74.56 397.54 77.41 397.5 77.41 400.35"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="73.5 278.52 70.65 279.4 70.65 276.55 73.5 275.67 73.5 278.52"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 284.06 70.65 284.9 70.65 282.05 73.5 281.21 73.5 284.06"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 289.6 70.65 290.4 70.65 287.55 73.5 286.75 73.5 289.6"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 300.68 70.65 301.41 70.65 298.56 73.5 297.83 73.5 300.68"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 317.3 70.65 317.91 70.65 315.06 73.5 314.45 73.5 317.3"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 322.84 70.65 323.42 70.65 320.57 73.5 319.99 73.5 322.84"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 328.38 70.65 328.92 70.65 326.07 73.5 325.53 73.5 328.38"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 333.92 70.65 334.42 70.65 331.57 73.5 331.07 73.5 333.92"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 339.46 70.65 339.92 70.65 337.07 73.5 336.61 73.5 339.46"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 345 70.65 345.42 70.65 342.57 73.5 342.15 73.5 345"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 356.08 70.65 356.43 70.65 353.58 73.5 353.23 73.5 356.08"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 361.62 70.65 361.93 70.65 359.08 73.5 358.77 73.5 361.62"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 378.24 70.65 378.44 70.65 375.59 73.5 375.39 73.5 378.24"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 383.78 70.65 383.94 70.65 381.09 73.5 380.93 73.5 383.78"
                    />
                    <polygon
                      class="cls-1"
                      points="73.5 389.32 70.65 389.44 70.65 386.59 73.5 386.47 73.5 389.32"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="69.58 274.24 66.73 275.17 66.73 272.32 69.58 271.39 69.58 274.24"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 279.73 66.73 280.62 66.73 277.76 69.58 276.88 69.58 279.73"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 285.22 66.73 286.06 66.73 283.21 69.58 282.37 69.58 285.22"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 296.19 66.73 296.96 66.73 294.11 69.58 293.34 69.58 296.19"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 307.17 66.73 307.86 66.73 305.01 69.58 304.32 69.58 307.17"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 312.66 66.73 313.31 66.73 310.46 69.58 309.81 69.58 312.66"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 318.14 66.73 318.76 66.73 315.91 69.58 315.29 69.58 318.14"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 329.12 66.73 329.66 66.73 326.81 69.58 326.27 69.58 329.12"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 334.61 66.73 335.11 66.73 332.26 69.58 331.76 69.58 334.61"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 345.58 66.73 346 66.73 343.15 69.58 342.73 69.58 345.58"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 351.07 66.73 351.45 66.73 348.6 69.58 348.22 69.58 351.07"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 356.56 66.73 356.9 66.73 354.05 69.58 353.71 69.58 356.56"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 367.53 66.73 367.8 66.73 364.95 69.58 364.68 69.58 367.53"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 373.02 66.73 373.25 66.73 370.4 69.58 370.17 69.58 373.02"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 383.99 66.73 384.15 66.73 381.3 69.58 381.14 69.58 383.99"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 389.48 66.73 389.6 66.73 386.75 69.58 386.63 69.58 389.48"
                    />
                    <polygon
                      class="cls-1"
                      points="69.58 400.46 66.73 400.5 66.73 397.65 69.58 397.61 69.58 400.46"
                    />
                    <rect
                      class="cls-1"
                      x="66.73"
                      y="403.09"
                      width="2.85"
                      height="2.85"
                    />
                    <rect
                      class="cls-1"
                      x="66.73"
                      y="416.74"
                      width="2.85"
                      height="2.85"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="92.43 273.66 94.52 274.55 94.52 271.69 92.43 270.81 92.43 273.66"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 285.16 94.52 285.97 94.52 283.12 92.43 282.31 92.43 285.16"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 290.92 94.52 291.69 94.52 288.83 92.43 288.07 92.43 290.92"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 308.17 94.52 308.82 94.52 305.97 92.43 305.32 92.43 308.17"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 313.92 94.52 314.54 94.52 311.69 92.43 311.07 92.43 313.92"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 319.67 94.52 320.25 94.52 317.4 92.43 316.82 92.43 319.67"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 331.18 94.52 331.68 94.52 328.82 92.43 328.33 92.43 331.18"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 336.93 94.52 337.39 94.52 334.54 92.43 334.08 92.43 336.93"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 342.68 94.52 343.1 94.52 340.25 92.43 339.83 92.43 342.68"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 354.18 94.52 354.53 94.52 351.68 92.43 351.33 92.43 354.18"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 359.93 94.52 360.24 94.52 357.39 92.43 357.08 92.43 359.93"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 365.69 94.52 365.95 94.52 363.1 92.43 362.83 92.43 365.69"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 371.44 94.52 371.67 94.52 368.82 92.43 368.59 92.43 371.44"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 382.94 94.52 383.09 94.52 380.24 92.43 380.09 92.43 382.94"
                    />
                    <polygon
                      class="cls-1"
                      points="92.43 388.69 94.52 388.81 94.52 385.96 92.43 385.84 92.43 388.69"
                    />
                    <rect
                      class="cls-1"
                      x="92.43"
                      y="403.09"
                      width="2.09"
                      height="2.85"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="95.3 269.18 97.39 270.1 97.39 267.25 95.3 266.33 95.3 269.18"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 272.91 95.3 272.03 95.3 274.87 97.39 275.76 97.39 272.91"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 278.57 95.3 277.72 95.3 280.57 97.39 281.42 97.39 278.57"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 289.89 95.3 289.12 95.3 291.97 97.39 292.74 97.39 289.89"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 295.55 95.3 294.82 95.3 297.67 97.39 298.4 97.39 295.55"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 301.21 95.3 300.52 95.3 303.37 97.39 304.06 97.39 301.21"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 306.87 95.3 306.22 95.3 309.07 97.39 309.72 97.39 306.87"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 318.19 95.3 317.61 95.3 320.47 97.39 321.04 97.39 318.19"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 323.85 95.3 323.31 95.3 326.16 97.39 326.7 97.39 323.85"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 329.51 95.3 329.01 95.3 331.86 97.39 332.36 97.39 329.51"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 340.83 95.3 340.41 95.3 343.26 97.39 343.68 97.39 340.83"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 346.49 95.3 346.11 95.3 348.96 97.39 349.34 97.39 346.49"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 352.15 95.3 351.81 95.3 354.66 97.39 355 97.39 352.15"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 357.81 95.3 357.5 95.3 360.36 97.39 360.66 97.39 357.81"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 363.47 95.3 363.2 95.3 366.05 97.39 366.32 97.39 363.47"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 369.13 95.3 368.9 95.3 371.75 97.39 371.98 97.39 369.13"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 374.79 95.3 374.6 95.3 377.45 97.39 377.64 97.39 374.79"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 386.11 95.3 386 95.3 388.85 97.39 388.96 97.39 386.11"
                    />
                    <polygon
                      class="cls-1"
                      points="97.39 391.77 95.3 391.7 95.3 394.55 97.39 394.62 97.39 391.77"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="98.17 270.44 100.26 271.37 100.26 268.52 98.17 267.59 98.17 270.44"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 276.09 100.26 276.97 100.26 274.12 98.17 273.24 98.17 276.09"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 281.73 100.26 282.58 100.26 279.73 98.17 278.88 98.17 281.73"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 287.38 100.26 288.19 100.26 285.34 98.17 284.53 98.17 287.38"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 293.03 100.26 293.8 100.26 290.95 98.17 290.18 98.17 293.03"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 304.32 100.26 305.01 100.26 302.16 98.17 301.47 98.17 304.32"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 309.96 100.26 310.62 100.26 307.77 98.17 307.11 98.17 309.96"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 315.61 100.26 316.23 100.26 313.37 98.17 312.76 98.17 315.61"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 321.26 100.26 321.83 100.26 318.98 98.17 318.41 98.17 321.26"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 338.19 100.26 338.66 100.26 335.81 98.17 335.34 98.17 338.19"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 343.84 100.26 344.26 100.26 341.41 98.17 340.99 98.17 343.84"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 349.49 100.26 349.87 100.26 347.02 98.17 346.64 98.17 349.49"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 366.42 100.26 366.69 100.26 363.84 98.17 363.57 98.17 366.42"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 383.36 100.26 383.52 100.26 380.66 98.17 380.51 98.17 383.36"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 389.01 100.26 389.12 100.26 386.27 98.17 386.16 98.17 389.01"
                    />
                    <polygon
                      class="cls-1"
                      points="98.17 394.65 100.26 394.73 100.26 391.88 98.17 391.8 98.17 394.65"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="101.04 271.71 103.13 272.63 103.13 269.78 101.04 268.86 101.04 271.71"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 277.3 103.13 278.19 103.13 275.34 101.04 274.45 101.04 277.3"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 282.9 103.13 283.74 103.13 280.89 101.04 280.05 101.04 282.9"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 288.49 103.13 289.3 103.13 286.45 101.04 285.64 101.04 288.49"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 294.08 103.13 294.85 103.13 292 101.04 291.23 101.04 294.08"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 299.68 103.13 300.41 103.13 297.55 101.04 296.83 101.04 299.68"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 305.27 103.13 305.96 103.13 303.11 101.04 302.42 101.04 305.27"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 310.86 103.13 311.52 103.13 308.66 101.04 308.01 101.04 310.86"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 316.45 103.13 317.07 103.13 314.22 101.04 313.61 101.04 316.45"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 322.05 103.13 322.62 103.13 319.78 101.04 319.2 101.04 322.05"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 327.64 103.13 328.18 103.13 325.33 101.04 324.79 101.04 327.64"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 338.83 103.13 339.29 103.13 336.44 101.04 335.98 101.04 338.83"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 350.01 103.13 350.4 103.13 347.55 101.04 347.16 101.04 350.01"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 355.61 103.13 355.95 103.13 353.1 101.04 352.76 101.04 355.61"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 361.2 103.13 361.51 103.13 358.66 101.04 358.35 101.04 361.2"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 383.57 103.13 383.73 103.13 380.87 101.04 380.72 101.04 383.57"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 389.16 103.13 389.28 103.13 386.43 101.04 386.32 101.04 389.16"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 394.76 103.13 394.84 103.13 391.99 101.04 391.91 101.04 394.76"
                    />
                    <polygon
                      class="cls-1"
                      points="101.04 400.35 103.13 400.39 103.13 397.54 101.04 397.5 101.04 400.35"
                    />
                    <rect
                      class="cls-1"
                      x="101.04"
                      y="403.09"
                      width="2.09"
                      height="2.85"
                    />
                    <rect
                      class="cls-1"
                      x="101.04"
                      y="416.74"
                      width="2.09"
                      height="2.85"
                    />
                    <rect
                      class="cls-1"
                      x="101.04"
                      y="430.38"
                      width="2.09"
                      height="2.85"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="103.91 272.98 106 273.9 106 271.05 103.91 270.13 103.91 272.98"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 278.52 106 279.4 106 276.55 103.91 275.67 103.91 278.52"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 284.06 106 284.9 106 282.05 103.91 281.21 103.91 284.06"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 289.6 106 290.4 106 287.55 103.91 286.75 103.91 289.6"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 295.14 106 295.91 106 293.06 103.91 292.29 103.91 295.14"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 300.68 106 301.41 106 298.56 103.91 297.83 103.91 300.68"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 306.22 106 306.91 106 304.06 103.91 303.37 103.91 306.22"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 317.3 106 317.91 106 315.06 103.91 314.45 103.91 317.3"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 322.84 106 323.42 106 320.57 103.91 319.99 103.91 322.84"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 328.38 106 328.92 106 326.07 103.91 325.53 103.91 328.38"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 333.92 106 334.42 106 331.57 103.91 331.07 103.91 333.92"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 339.46 106 339.92 106 337.07 103.91 336.61 103.91 339.46"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 345 106 345.42 106 342.57 103.91 342.15 103.91 345"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 350.54 106 350.93 106 348.08 103.91 347.69 103.91 350.54"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 361.62 106 361.93 106 359.08 103.91 358.77 103.91 361.62"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 372.7 106 372.93 106 370.08 103.91 369.85 103.91 372.7"
                    />
                    <polygon
                      class="cls-1"
                      points="103.91 383.78 106 383.94 106 381.09 103.91 380.93 103.91 383.78"
                    />
                  </g>
                  <g>
                    <polygon
                      class="cls-1"
                      points="106.78 274.24 108.87 275.17 108.87 272.32 106.78 271.39 106.78 274.24"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 279.73 108.87 280.62 108.87 277.76 106.78 276.88 106.78 279.73"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 296.19 108.87 296.96 108.87 294.11 106.78 293.34 106.78 296.19"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 301.68 108.87 302.41 108.87 299.56 106.78 298.83 106.78 301.68"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 307.17 108.87 307.86 108.87 305.01 106.78 304.32 106.78 307.17"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 312.66 108.87 313.31 108.87 310.46 106.78 309.81 106.78 312.66"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 323.63 108.87 324.21 108.87 321.36 106.78 320.78 106.78 323.63"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 329.12 108.87 329.66 108.87 326.81 106.78 326.27 106.78 329.12"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 340.09 108.87 340.56 108.87 337.71 106.78 337.24 106.78 340.09"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 345.58 108.87 346 108.87 343.15 106.78 342.73 106.78 345.58"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 351.07 108.87 351.45 108.87 348.6 106.78 348.22 106.78 351.07"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 356.56 108.87 356.9 108.87 354.05 106.78 353.71 106.78 356.56"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 362.04 108.87 362.35 108.87 359.5 106.78 359.2 106.78 362.04"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 367.53 108.87 367.8 108.87 364.95 106.78 364.68 106.78 367.53"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 373.02 108.87 373.25 108.87 370.4 106.78 370.17 106.78 373.02"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 389.48 108.87 389.6 108.87 386.75 106.78 386.63 106.78 389.48"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 394.97 108.87 395.05 108.87 392.2 106.78 392.12 106.78 394.97"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 427.42 108.87 427.49 108.87 424.64 106.78 424.57 106.78 427.42"
                    />
                    <polygon
                      class="cls-1"
                      points="106.78 400.46 108.87 400.5 108.87 397.65 106.78 397.61 106.78 400.46"
                    />
                    <rect
                      class="cls-1"
                      x="106.78"
                      y="403.09"
                      width="2.09"
                      height="2.85"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="cls-1"
                      d="M481.85,185.8v-16.02c-1.2,.39-2.41,.78-3.61,1.17-.46,.15-.2,2.49-.2,3.02v28.43h0v5.02h0v3.81h0v5.02h0v3.81h0v206.03h3.81v-75.89h0v-3.81h0v-11.74h0v-7.14h0v-8.41h0v-7.14h0v-8.41h0v-10.47h0v-1.75h0v-13.81h0v-16.19h0v-3.81h0v-.64h0v-3.81h0v-.64h0v-3.81h0v-3.97h0v-3.81h0v-1.75h0v-3.81h0v-.64h0v-8.25h0v-13.96h0v-3.81h0v-.64h0v-3.81h0v-5.55c0-4.28-.01-8.57,0-12.85v.03Z"
                    />
                    <path
                      class="cls-1"
                      d="M476.62,182.98v-11.51c-1.2,.39-2.41,.78-3.61,1.17-.4,.13-.2,2.23-.2,2.66v29.11h0v3.81h0v13.68h0v3.81h0v13.68h0v3.81h0v167.87h3.81v-61.89h0v-3.81h0v-13.8h0v-3.81h0v-6.1h0v-3.81h0v-3.89h0v-3.81h0v-6.1h0v-6.01h0v-1.69h0v-6.01h0v-1.69h0v-6.01h0v-24.81h0v-3.81h0v-.59h0v-29.12h0v-.59h0v-40.74Z"
                    />
                    <path
                      class="cls-1"
                      d="M471.4,174.25v-1.09c-1.2,.39-2.41,.78-3.61,1.17-.44,.14-.2,2.67-.2,3.11v37.02h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v12.47h0v15.68h0v3.81h0v2.69h0v116.33h3.81v-80.32h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-13.64h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-1.64h0v-3.81h0v-3.83h0v-8.17h0v-9.28h0v-8.17h0v-.55h0v-3.81h0v-1.64h0v-3.81h0v-27.26h0Z"
                    />
                    <path
                      class="cls-1"
                      d="M466.17,181.9v-7.05c-1.2,.39-2.41,.78-3.61,1.17-.45,.15-.2,2.41-.2,2.94v23.46h0v17.65h0v3.81h0v.48h0v3.81h0v17.65h0v3.81h0v.48h0v3.81h0v123.38h3.81v-32.28h0v-4.89h0v-21.04h0v-7.05h0v-.51h0v-17.28h0v-10.29h0v-3.75h0v-11.88h0v-3.81h0v-.51h0v-3.81h0v-.51h0v-3.81h0v-1.59h0v-7.05h0v-.51h0v-3.81h0v-.51h0v-8.13h0v-3.75h0v-4.89h0v-4.83h0v-3.81h0v-.51h0v-3.81h0v-4.83h0v-3.81h0v-22.14Z"
                    />
                    <path
                      class="cls-1"
                      d="M460.94,192.12v-15.58c-1.17,.38-2.34,.76-3.51,1.14-.5,.16-.3,.61-.3,1.11v21.3h0v3.81h0v.44h0v3.81h0v4.69h0v3.81h0v1.51h0v3.81h0v2.57h0v4.87h0v1.5h0v11.24h0v1.51h0v11.25h0v.44h0v3.81h0v4.69h0v3.81h0v12.13h0v7h0v.44h0v7h0v.44h0v3.81h0v3.63h0v3.81h0v.44h0v3.81h0v3.63h0v3.81h0v7.88h0v3.81h0v15.32h0v3.81h0v9.56h3.81v-101.65h0v-3.81h0v-9.03h0v-3.81h0v-17.59h0v-3.81h0v-9.03h0v-3.81h0v-13.33Z"
                    />
                    <path
                      class="cls-1"
                      d="M455.71,179.3v-1.06c-1.03,.33-2.06,.67-3.1,1-.46,.15-.71,.07-.71,.56v21.77h0v3.81h0v4.61h0v3.81h0v4.61h0v8.02h0v3.56h0v4.86h0v1.45h0v6.96h0v.4h0v12.23h0v.4h0v3.81h0v.4h0v3.81h0v.4h0v3.81h0v15.14h0v5.91h0v.4h0v6.31h0v5.91h0v.4h0v7.37h0v4.86h0v7.77h0v3.81h0v8.82h0v3.81h0v8.82h0v3.81h0v61.9h3.81v-69.36h0v-3.81h0v-20.57h0v-10.17h0v-2.55h0v-6.99h0v-4.67h0v-16.53h0v-8.91h0v-3.81h0v-.43h0v-3.81h0v-.43h0v-3.81h0v-.43h0v-3.81h0v-.43h0v-6.99h0v-.43h0v-3.81h0v-1.49h0v-3.81h0v-.43h0v-12.29h0v-7.85h0v-3.81h0v-1.49h0v-3.81h0v-.43h0v-3.81h0v-.43h0v-22.86Z"
                    />
                  </g>
                  <rect
                    class="cls-1"
                    x="477.97"
                    y="443.31"
                    width="3.88"
                    height="13.8"
                  />
                  <rect
                    class="cls-1"
                    x="472.77"
                    y="421.21"
                    width="3.86"
                    height="8.6"
                  />
                  <rect
                    class="cls-1"
                    x="462.29"
                    y="405.42"
                    width="3.88"
                    height="21.14"
                  />
                  <rect
                    class="cls-1"
                    x="462.29"
                    y="463.69"
                    width="3.88"
                    height="11.04"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="154.27 200.96 150.36 202.69 150.36 197.35 154.27 195.62 154.27 200.96"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 211.72 150.36 213.38 150.36 208.04 154.27 206.39 154.27 211.72"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 222.49 150.36 224.07 150.36 218.74 154.27 217.15 154.27 222.49"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 233.25 150.36 234.77 150.36 229.43 154.27 227.92 154.27 233.25"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 244.02 150.36 245.46 150.36 240.12 154.27 238.68 154.27 244.02"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 254.78 150.36 256.15 150.36 250.82 154.27 249.45 154.27 254.78"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 265.55 150.36 266.84 150.36 261.51 154.27 260.21 154.27 265.55"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 276.32 150.36 277.54 150.36 272.2 154.27 270.98 154.27 276.32"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 308.61 150.36 309.62 150.36 304.28 154.27 303.28 154.27 308.61"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 319.38 150.36 320.31 150.36 314.97 154.27 314.04 154.27 319.38"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 330.14 150.36 331 150.36 325.67 154.27 324.81 154.27 330.14"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 340.91 150.36 341.7 150.36 336.36 154.27 335.57 154.27 340.91"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 362.44 150.36 363.08 150.36 357.75 154.27 357.1 154.27 362.44"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 373.2 150.36 373.78 150.36 368.44 154.27 367.86 154.27 373.2"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 405.49 150.36 405.86 150.36 400.52 154.27 400.16 154.27 405.49"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 427.03 150.36 427.24 150.36 421.91 154.27 421.69 154.27 427.03"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 437.79 150.36 437.94 150.36 432.6 154.27 432.46 154.27 437.79"
                  />
                  <polygon
                    class="cls-1"
                    points="154.27 448.56 150.36 448.63 150.36 443.29 154.27 443.22 154.27 448.56"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="148.9 203.33 144.99 205.06 144.99 199.72 148.9 197.99 148.9 203.33"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 220.91 148.9 219.33 148.9 224.66 144.99 226.24 144.99 220.91"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 231.51 148.9 229.99 148.9 235.33 144.99 236.84 144.99 231.51"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 252.69 148.9 251.33 148.9 256.66 144.99 258.03 144.99 252.69"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 263.29 148.9 261.99 148.9 267.33 144.99 268.62 144.99 263.29"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 273.88 148.9 272.66 148.9 277.99 144.99 279.22 144.99 273.88"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 284.48 148.9 283.33 148.9 288.66 144.99 289.81 144.99 284.48"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 295.07 148.9 293.99 148.9 299.33 144.99 300.41 144.99 295.07"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 316.26 148.9 315.32 148.9 320.66 144.99 321.59 144.99 316.26"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 326.86 148.9 325.99 148.9 331.33 144.99 332.19 144.99 326.86"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 348.04 148.9 347.32 148.9 352.66 144.99 353.38 144.99 348.04"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 358.64 148.9 357.99 148.9 363.32 144.99 363.97 144.99 358.64"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 369.23 148.9 368.66 148.9 373.99 144.99 374.57 144.99 369.23"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 379.83 148.9 379.32 148.9 384.66 144.99 385.16 144.99 379.83"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 390.42 148.9 389.99 148.9 395.32 144.99 395.76 144.99 390.42"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 401.01 148.9 400.66 148.9 405.99 144.99 406.35 144.99 401.01"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 411.61 148.9 411.32 148.9 416.66 144.99 416.94 144.99 411.61"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 432.8 148.9 432.65 148.9 437.99 144.99 438.13 144.99 432.8"
                  />
                  <polygon
                    class="cls-1"
                    points="144.99 443.39 148.9 443.32 148.9 448.66 144.99 448.73 144.99 443.39"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="143.53 216.27 139.62 217.92 139.62 212.59 143.53 210.93 143.53 216.27"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 226.84 139.62 228.42 139.62 223.08 143.53 221.5 143.53 226.84"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 237.4 139.62 238.91 139.62 233.58 143.53 232.07 143.53 237.4"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 247.97 139.62 249.41 139.62 244.07 143.53 242.64 143.53 247.97"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 269.11 139.62 270.4 139.62 265.07 143.53 263.77 143.53 269.11"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 279.67 139.62 280.9 139.62 275.56 143.53 274.34 143.53 279.67"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 290.24 139.62 291.39 139.62 286.06 143.53 284.91 143.53 290.24"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 300.81 139.62 301.89 139.62 296.55 143.53 295.47 143.53 300.81"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 311.38 139.62 312.38 139.62 307.05 143.53 306.04 143.53 311.38"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 321.94 139.62 322.88 139.62 317.54 143.53 316.61 143.53 321.94"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 343.08 139.62 343.87 139.62 338.53 143.53 337.74 143.53 343.08"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 353.65 139.62 354.37 139.62 349.03 143.53 348.31 143.53 353.65"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 364.21 139.62 364.86 139.62 359.53 143.53 358.88 143.53 364.21"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 385.35 139.62 385.85 139.62 380.52 143.53 380.01 143.53 385.35"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 395.92 139.62 396.35 139.62 391.01 143.53 390.58 143.53 395.92"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 417.05 139.62 417.34 139.62 412 143.53 411.72 143.53 417.05"
                  />
                  <polygon
                    class="cls-1"
                    points="143.53 427.62 139.62 427.83 139.62 422.5 143.53 422.28 143.53 427.62"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="138.16 208.07 134.25 209.8 134.25 204.46 138.16 202.74 138.16 208.07"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 239.48 134.25 240.99 134.25 235.65 138.16 234.14 138.16 239.48"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 249.95 134.25 251.39 134.25 246.05 138.16 244.61 138.16 249.95"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 270.88 134.25 272.18 134.25 266.84 138.16 265.55 138.16 270.88"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 302.29 134.25 303.37 134.25 298.03 138.16 296.96 138.16 302.29"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 323.23 134.25 324.16 134.25 318.83 138.16 317.89 138.16 323.23"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 333.7 134.25 334.56 134.25 329.22 138.16 328.36 138.16 333.7"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 344.17 134.25 344.96 134.25 339.62 138.16 338.83 138.16 344.17"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 354.63 134.25 355.35 134.25 350.02 138.16 349.3 138.16 354.63"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 375.57 134.25 376.15 134.25 370.81 138.16 370.24 138.16 375.57"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 396.51 134.25 396.94 134.25 391.61 138.16 391.17 138.16 396.51"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 406.98 134.25 407.34 134.25 402 138.16 401.64 138.16 406.98"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 417.45 134.25 417.74 134.25 412.4 138.16 412.11 138.16 417.45"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 427.91 134.25 428.13 134.25 422.8 138.16 422.58 138.16 427.91"
                  />
                  <polygon
                    class="cls-1"
                    points="138.16 438.38 134.25 438.53 134.25 433.19 138.16 433.05 138.16 438.38"
                  />
                  <rect
                    class="cls-1"
                    x="134.25"
                    y="453.99"
                    width="3.91"
                    height="5.34"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="132.79 210.44 128.87 212.17 128.87 206.83 132.79 205.11 132.79 210.44"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 220.81 128.87 222.47 128.87 217.13 132.79 215.48 132.79 220.81"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 231.18 128.87 232.76 128.87 227.43 132.79 225.85 132.79 231.18"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 251.92 128.87 253.36 128.87 248.03 132.79 246.59 132.79 251.92"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 262.29 128.87 263.66 128.87 258.32 132.79 256.96 132.79 262.29"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 283.03 128.87 284.26 128.87 278.92 132.79 277.7 132.79 283.03"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 293.4 128.87 294.55 128.87 289.22 132.79 288.07 132.79 293.4"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 314.14 128.87 315.15 128.87 309.81 132.79 308.81 132.79 314.14"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 334.88 128.87 335.75 128.87 330.41 132.79 329.55 132.79 334.88"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 355.62 128.87 356.34 128.87 351.01 132.79 350.29 132.79 355.62"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 365.99 128.87 366.64 128.87 361.3 132.79 360.66 132.79 365.99"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 376.36 128.87 376.94 128.87 371.6 132.79 371.03 132.79 376.36"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 407.47 128.87 407.83 128.87 402.5 132.79 402.14 132.79 407.47"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 438.58 128.87 438.73 128.87 433.39 132.79 433.25 132.79 438.58"
                  />
                  <polygon
                    class="cls-1"
                    points="132.79 448.95 128.87 449.02 128.87 443.69 132.79 443.62 132.79 448.95"
                  />
                  <rect
                    class="cls-1"
                    x="128.87"
                    y="453.99"
                    width="3.91"
                    height="5.34"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="127.41 212.81 123.5 214.54 123.5 209.21 127.41 207.48 127.41 212.81"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 223.08 123.5 224.74 123.5 219.41 127.41 217.75 127.41 223.08"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 233.36 123.5 234.94 123.5 229.6 127.41 228.02 127.41 233.36"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 243.63 123.5 245.14 123.5 239.8 127.41 238.29 127.41 243.63"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 253.9 123.5 255.34 123.5 250 127.41 248.56 127.41 253.9"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 264.17 123.5 265.54 123.5 260.2 127.41 258.84 127.41 264.17"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 274.44 123.5 275.74 123.5 270.4 127.41 269.11 127.41 274.44"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 284.71 123.5 285.94 123.5 280.6 127.41 279.38 127.41 284.71"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 294.98 123.5 296.14 123.5 290.8 127.41 289.65 127.41 294.98"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 305.25 123.5 306.33 123.5 301 127.41 299.92 127.41 305.25"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 315.53 123.5 316.53 123.5 311.2 127.41 310.19 127.41 315.53"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 325.8 123.5 326.73 123.5 321.4 127.41 320.46 127.41 325.8"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 366.88 123.5 367.53 123.5 362.19 127.41 361.55 127.41 366.88"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 377.15 123.5 377.73 123.5 372.39 127.41 371.82 127.41 377.15"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 387.42 123.5 387.93 123.5 382.59 127.41 382.09 127.41 387.42"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 397.7 123.5 398.13 123.5 392.79 127.41 392.36 127.41 397.7"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 407.97 123.5 408.32 123.5 402.99 127.41 402.63 127.41 407.97"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 418.24 123.5 418.53 123.5 413.19 127.41 412.9 127.41 418.24"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 428.51 123.5 428.72 123.5 423.39 127.41 423.17 127.41 428.51"
                  />
                  <polygon
                    class="cls-1"
                    points="127.41 438.78 123.5 438.92 123.5 433.59 127.41 433.44 127.41 438.78"
                  />
                </g>
                <g>
                  <g>
                    <path
                      class="cls-1"
                      d="M157.53,209.98v-16.02c1.2,.39,2.41,.78,3.61,1.17,.46,.15,.2,2.49,.2,3.03v28.43h0v5.02h0v3.81h0v5.02h0v3.81h0v206.03h-3.81v-75.89h0v-3.81h0v-11.74h0v-7.14h0v-8.41h0v-7.14h0v-8.41h0v-10.47h0v-1.75h0v-13.8h0v-16.19h0v-3.81h0v-.63h0v-3.81h0v-.64h0v-3.81h0v-3.97h0v-3.81h0v-1.75h0v-3.81h0v-.64h0v-8.25h0v-13.96h0v-3.81h0v-.64h0v-3.81h0v-5.55c0-4.28,0-8.57,0-12.85h0Z"
                    />
                    <path
                      class="cls-1"
                      d="M162.76,207.16v-11.51c1.2,.39,2.41,.78,3.61,1.17,.4,.13,.2,2.23,.2,2.66v29.11h0v3.81h0v13.68h0v3.81h0v13.68h0v3.81h0v167.87h-3.81v-61.89h0v-3.81h0v-13.8h0v-3.81h0v-6.1h0v-3.81h0v-3.89h0v-3.81h0v-6.1h0v-6.01h0v-1.69h0v-6.01h0v-1.69h0v-6.01h0v-24.81h0v-3.81h0v-.59h0v-29.12h0v-.59h0v-40.74Z"
                    />
                    <path
                      class="cls-1"
                      d="M167.98,198.43v-1.09c1.2,.39,2.41,.78,3.61,1.17,.44,.14,.2,2.67,.2,3.11v37.02h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v3.81h0v.52h0v12.47h0v15.68h0v3.81h0v2.69h0v116.33h-3.81v-80.32h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-13.64h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-.55h0v-3.81h0v-1.64h0v-3.81h0v-3.83h0v-8.17h0v-9.28h0v-8.17h0v-.55h0v-3.81h0v-1.65h0v-3.81h0v-27.24Z"
                    />
                    <path
                      class="cls-1"
                      d="M173.21,206.08v-7.05c1.2,.39,2.41,.78,3.61,1.17,.45,.15,.2,2.41,.2,2.94v23.46h0v17.65h0v3.81h0v.48h0v3.81h0v17.65h0v3.81h0v.48h0v3.81h0v123.38h-3.81v-32.28h0v-4.89h0v-21.04h0v-7.05h0v-.51h0v-17.28h0v-10.29h0v-3.75h0v-11.88h0v-3.81h0v-.51h0v-3.81h0v-.51h0v-3.81h0v-1.59h0v-7.05h0v-.51h0v-3.81h0v-.51h0v-8.13h0v-3.75h0v-4.89h0v-4.83h0v-3.81h0v-.51h0v-3.81h0v-4.83h0v-3.81h0v-22.14Z"
                    />
                    <path
                      class="cls-1"
                      d="M178.44,216.3v-15.58c1.17,.38,2.34,.76,3.51,1.14,.5,.16,.3,.61,.3,1.11v21.31h0v3.81h0v.44h0v3.81h0v4.69h0v3.81h0v1.51h0v3.81h0v2.57h0v4.87h0v1.5h0v11.24h0v1.51h0v11.25h0v.44h0v3.81h0v4.69h0v3.81h0v12.13h0v7h0v.44h0v7h0v.44h0v3.81h0v3.63h0v3.81h0v.44h0v3.81h0v3.63h0v3.81h0v7.88h0v3.81h0v15.32h0v3.81h0v9.56h-3.81v-101.65h0v-3.81h0v-9.03h0v-3.81h0v-17.59h0v-3.81h0v-9.03h0v-3.81h0v-13.34Z"
                    />
                    <path
                      class="cls-1"
                      d="M183.67,203.48v-1.06c1.03,.34,2.07,.67,3.1,1,.46,.15,.71,.07,.71,.56v21.77h0v3.81h0v4.61h0v3.81h0v4.61h0v8.02h0v3.56h0v4.86h0v1.45h0v6.96h0v.4h0v12.23h0v.4h0v3.81h0v.4h0v3.81h0v.4h0v3.81h0v15.14h0v5.91h0v.4h0v6.31h0v5.91h0v.4h0v7.37h0v4.86h0v7.77h0v3.81h0v8.82h0v3.81h0v8.82h0v3.81h0v61.9h-3.81v-69.36h0v-3.81h0v-20.57h0v-10.17h0v-2.55h0v-6.99h0v-4.67h0v-16.53h0v-8.91h0v-3.81h0v-.43h0v-3.81h0v-.43h0v-3.81h0v-.43h0v-3.81h0v-.43h0v-6.99h0v-.43h0v-3.81h0v-1.49h0v-3.81h0v-.43h0v-12.29h0v-7.85h0v-3.81h0v-1.49h0v-3.81h0v-.43h0v-3.81h0v-.43h0v-22.86Z"
                    />
                  </g>
                  <rect
                    class="cls-1"
                    x="157.53"
                    y="467.49"
                    width="3.88"
                    height="13.8"
                  />
                  <rect
                    class="cls-1"
                    x="162.75"
                    y="445.39"
                    width="3.86"
                    height="8.6"
                  />
                  <rect
                    class="cls-1"
                    x="173.21"
                    y="429.6"
                    width="3.88"
                    height="21.14"
                  />
                  <rect
                    class="cls-1"
                    x="173.21"
                    y="487.88"
                    width="3.88"
                    height="11.04"
                  />
                </g>
                <g>
                  <g>
                    <path
                      class="cls-1"
                      d="M32.28,257.61v-13.55c1.02,.33,2.04,.66,3.05,.99,.39,.12,.17,2.1,.17,2.56v24.04h0v4.24h0v3.22h0v4.24h0v3.22h0v174.21h-3.22v-64.17h0v-3.22h0v-9.93h0v-6.04h0v-7.11h0v-6.04h0v-7.11h0v-8.85h0v-1.48h0v-11.67h0v-13.68h0v-3.22h0v-.54h0v-3.22h0v-.54h0v-3.22h0v-3.36h0v-3.22h0v-1.48h0v-3.22h0v-.54h0v-6.98h0v-11.81h0v-3.22h0v-.54h0v-3.22h0v-4.7c0-3.62,0-7.25,0-10.87v.03Z"
                    />
                    <path
                      class="cls-1"
                      d="M36.7,255.23v-9.73c1.02,.33,2.04,.66,3.05,.99,.34,.11,.17,1.88,.17,2.24v24.61h0v3.22h0v11.57h0v3.22h0v11.57h0v3.22h0v141.94h-3.22v-52.33h0v-3.22h0v-11.67h0v-3.22h0v-5.15h0v-3.22h0v-3.29h0v-3.22h0v-5.16h0v-5.08h0v-1.43h0v-5.08h0v-1.43h0v-5.08h0v-20.97h0v-3.22h0v-.5h0v-24.62h0v-.5h0v-34.46Z"
                    />
                    <path
                      class="cls-1"
                      d="M41.12,247.85v-.92c1.02,.33,2.04,.66,3.05,.99,.37,.12,.17,2.25,.17,2.63v31.3h0v3.22h0v.44h0v3.22h0v.44h0v3.22h0v.44h0v3.22h0v.44h0v3.22h0v.44h0v3.22h0v.44h0v3.22h0v.44h0v10.54h0v13.26h0v3.22h0v2.27h0v98.36h-3.22v-67.91h0v-3.22h0v-.47h0v-3.22h0v-.47h0v-3.22h0v-.47h0v-3.22h0v-.47h0v-3.22h0v-.47h0v-3.22h0v-.47h0v-3.22h0v-.47h0v-3.22h0v-.47h0v-3.22h0v-11.53h0v-3.22h0v-.47h0v-3.22h0v-.47h0v-3.22h0v-.47h0v-3.22h0v-1.39h0v-3.22h0v-3.23h0v-6.91h0v-7.84h0v-6.91h0v-.47h0v-3.22h0v-1.39h0v-3.22h0v-23Z"
                    />
                    <path
                      class="cls-1"
                      d="M45.54,370.37v-2.66c1.02,.1,3.28,.24,3.28,.24,0,0-.06,1.55-.06,1.68v5.9h0v4.44h0v.96h0v.12h0v.96h0v4.44h0v.96h0v.12h0v.96h0v31.04h-3.22v-8.12h0v-1.23h0v-5.29h0v-1.77h0v-.13h0v-4.35h0v-2.59h0v-.94h0v-2.99h0v-.96h0v-.13h0v-.96h0v-.13h0v-.96h0v-.4h0v-1.77h0v-.13h0v-.96h0v-.13h0v-2.04h0v-.95h0v-1.23h0v-1.22h0v-.96h0v-.13h0v-.96h0v-1.22h0v-.96h0v-5.56h0Z"
                    />
                    <path
                      class="cls-1"
                      d="M45.54,252.42v-4.06c1.02,.22,2.04,.45,3.05,.67,.38,.08,.17,1.39,.17,1.7v13.53h0v10.18h0v2.2h0v.28h0v2.2h0v10.18h0v2.2h0v.28h0v2.2h0v59.38l-.17,11.94-3.05-.17v-18.62h0v-2.82h0v-12.13h0v-4.07h0v-.29h0v-9.97h0v-5.93h0v-2.16h0v-6.85h0v-2.2h0v-.29h0v-2.2h0v-.29h0v-2.2h0v-.92h0v-4.07h0v-.29h0v-2.2h0v-.3h0v-4.69h0v-2.16h0v-2.82h0v-2.79h0v-2.19h0v-.3h0v-2.2h0v-2.79h0v-2.19h0v-12.78Z"
                    />
                    <path
                      class="cls-1"
                      d="M49.97,262.96v-13.17c.99,.32,1.98,.64,2.97,.96,.43,.14,.25,.52,.25,.94v18h0v3.22h0v.37h0v3.22h0v3.97h0v3.22h0v1.27h0v3.22h0v2.17h0v4.12h0v1.27h0v9.51h0v1.27h0v9.51h0v.38h0v3.22h0v3.97h0v3.22h0v10.26h0v5.91h0v.37h0v5.92h0v.37h0v3.22h0v3.07h0v3.22h0v.37h0v3.22h0v3.07h0v3.22h0v6.66h0v3.22h0v12.95h0v3.22h0v8.09h-3.22v-85.95h0v-3.22h0v-7.63h0v-3.22h0v-14.87h0v-3.22h0v-7.64h0v-3.22h0v-11.26h0Z"
                    />
                    <path
                      class="cls-1"
                      d="M54.39,251.72v-.5c.87,.16,1.75,.32,2.62,.48,.39,.07,.6,.04,.6,.27v10.31h0v1.8h0v2.19h0v1.8h0v2.18h0v3.8h0v1.69h0v2.3h0v.69h0v3.3h0v.19h0v5.79h0v.19h0v1.8h0v.19h0v1.8h0v.19h0v1.8h0v7.17h0v2.8h0v.19h0v2.99h0v2.8h0v.19h0v3.49h0v2.3h0v3.68h0v1.8h0v4.18h0v1.81h0v4.18h0v1.81h0v5.77l-3.22-.41v-8.89h0v-1.8h0v-9.74h0v-4.82h0v-1.21h0v-3.31h0v-2.21h0v-7.83h0v-4.22h0v-1.8h0v-.2h0v-1.8h0v-.2h0v-1.8h0v-.21h0v-1.8h0v-.2h0v-3.31h0v-.21h0v-1.8h0v-.71h0v-1.8h0v-.21h0v-5.82h0v-3.72h0v-1.8h0v-.71h0v-1.8h0v-.2h0v-1.8h0v-.2h0v-10.88Z"
                    />
                    <path
                      class="cls-1"
                      d="M54.39,341.72v-.38c.87,.12,1.75,.24,2.62,.36,.39,.05,.6,.03,.6,.2v31.82h0v1.37h0v1.66h0v1.37h0v1.66h0v2.89h0v1.28h0v1.75h0v.52h0v2.51h0v.15h0v4.41h0v.14h0v1.37h0v.15h0v1.37h0v.15h0v1.37h0v5.46h0v2.13h0v.14h0v2.28h0v2.13h0v.14h0v2.66h0v1.75h0v2.8h0v1.37h0v3.18h0v1.37h0v3.18h0v1.37h0v22.32h-3.22v-25.01h0v-1.37h0v-7.42h0v-3.67h0v-.92h0v-2.52h0v-1.68h0v-5.96h0v-3.21h0v-1.37h0v-.16h0v-1.37h0v-.16h0v-1.37h0v-.16h0v-1.37h0v-.16h0v-2.52h0v-.16h0v-1.37h0v-.54h0v-1.37h0v-.16h0v-4.43h0v-2.83h0v-1.37h0v-.54h0v-1.37h0v-.16h0v-1.37h0v-.16h0v-32.17Z"
                    />
                  </g>
                  <rect
                    class="cls-1"
                    x="32.28"
                    y="475.35"
                    width="3.28"
                    height="11.67"
                  />
                  <rect
                    class="cls-1"
                    x="36.7"
                    y="503.96"
                    width="3.26"
                    height="7.27"
                  />
                  <rect
                    class="cls-1"
                    x="45.54"
                    y="443.31"
                    width="3.28"
                    height="17.88"
                  />
                  <rect
                    class="cls-1"
                    x="45.54"
                    y="482.32"
                    width="3.28"
                    height="9.33"
                  />
                  <rect
                    class="cls-1"
                    x="54.36"
                    y="493.9"
                    width="3.28"
                    height="9.33"
                  />
                  <rect
                    class="cls-1"
                    x="54.36"
                    y="458.53"
                    width="3.28"
                    height="9.33"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="27.14 249.37 23.69 250.89 23.69 246.19 27.14 244.66 27.14 249.37"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 258.86 23.69 260.32 23.69 255.62 27.14 254.16 27.14 258.86"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 268.36 23.69 269.75 23.69 265.05 27.14 263.65 27.14 268.36"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 296.84 23.69 298.05 23.69 293.34 27.14 292.14 27.14 296.84"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 306.34 23.69 307.48 23.69 302.78 27.14 301.63 27.14 306.34"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 315.83 23.69 316.91 23.69 312.21 27.14 311.13 27.14 315.83"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 325.33 23.69 326.35 23.69 321.64 27.14 320.62 27.14 325.33"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 334.82 23.69 335.78 23.69 331.07 27.14 330.12 27.14 334.82"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 353.81 23.69 354.64 23.69 349.93 27.14 349.11 27.14 353.81"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 363.31 23.69 364.07 23.69 359.36 27.14 358.6 27.14 363.31"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 382.3 23.69 382.93 23.69 378.23 27.14 377.59 27.14 382.3"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 410.78 23.69 411.23 23.69 406.52 27.14 406.08 27.14 410.78"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 429.77 23.69 430.09 23.69 425.39 27.14 425.07 27.14 429.77"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 439.27 23.69 439.52 23.69 434.82 27.14 434.56 27.14 439.27"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 448.76 23.69 448.95 23.69 444.25 27.14 444.06 27.14 448.76"
                  />
                  <polygon
                    class="cls-1"
                    points="27.14 458.26 23.69 458.38 23.69 453.68 27.14 453.55 27.14 458.26"
                  />
                  <rect
                    class="cls-1"
                    x="23.69"
                    y="472.54"
                    width="3.45"
                    height="4.71"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="22.4 251.46 18.95 252.98 18.95 248.28 22.4 246.76 22.4 251.46"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 266.97 22.4 265.57 22.4 270.28 18.95 271.67 18.95 266.97"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 285.66 22.4 284.39 22.4 289.09 18.95 290.36 18.95 285.66"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 295 22.4 293.79 22.4 298.5 18.95 299.71 18.95 295"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 313.69 22.4 312.61 22.4 317.32 18.95 318.39 18.95 313.69"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 323.03 22.4 322.02 22.4 326.72 18.95 327.74 18.95 323.03"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 341.72 22.4 340.83 22.4 345.54 18.95 346.43 18.95 341.72"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 351.07 22.4 350.24 22.4 354.95 18.95 355.77 18.95 351.07"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 369.76 22.4 369.06 22.4 373.76 18.95 374.46 18.95 369.76"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 379.1 22.4 378.46 22.4 383.17 18.95 383.8 18.95 379.1"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 388.45 22.4 387.87 22.4 392.58 18.95 393.15 18.95 388.45"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 407.13 22.4 406.69 22.4 411.39 18.95 411.84 18.95 407.13"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 425.82 22.4 425.5 22.4 430.21 18.95 430.53 18.95 425.82"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 435.17 22.4 434.91 22.4 439.62 18.95 439.87 18.95 435.17"
                  />
                  <polygon
                    class="cls-1"
                    points="18.95 463.2 22.4 463.14 22.4 467.84 18.95 467.9 18.95 463.2"
                  />
                  <rect
                    class="cls-1"
                    x="18.95"
                    y="472.54"
                    width="3.45"
                    height="4.71"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="17.66 253.55 14.21 255.07 14.21 250.37 17.66 248.85 17.66 253.55"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 262.87 14.22 264.33 14.22 259.63 17.66 258.17 17.66 262.87"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 272.19 14.21 273.59 14.21 268.88 17.66 267.49 17.66 272.19"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 281.51 14.21 282.85 14.21 278.14 17.66 276.81 17.66 281.51"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 290.83 14.21 292.11 14.21 287.4 17.66 286.13 17.66 290.83"
                  />
                  <polygon
                    class="cls-1"
                    points="17.67 309.48 14.22 310.62 14.22 305.91 17.67 304.77 17.67 309.48"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 328.12 14.21 329.13 14.21 324.43 17.66 323.41 17.66 328.12"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 337.44 14.21 338.39 14.21 333.69 17.66 332.73 17.66 337.44"
                  />
                  <polygon
                    class="cls-1"
                    points="17.67 346.76 14.22 347.65 14.22 342.94 17.67 342.05 17.67 346.76"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 365.4 14.21 366.16 14.21 361.46 17.66 360.7 17.66 365.4"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 374.72 14.21 375.42 14.21 370.71 17.66 370.02 17.66 374.72"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 393.36 14.21 393.93 14.21 389.23 17.66 388.66 17.66 393.36"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 402.68 14.22 403.19 14.22 398.48 17.66 397.98 17.66 402.68"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 412 14.21 412.45 14.21 407.74 17.66 407.3 17.66 412"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 421.32 14.21 421.7 14.21 417 17.66 416.62 17.66 421.32"
                  />
                  <polygon
                    class="cls-1"
                    points="17.67 430.65 14.21 430.96 14.21 426.26 17.67 425.94 17.67 430.65"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 439.97 14.21 440.22 14.21 435.51 17.66 435.26 17.66 439.97"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 449.29 14.21 449.48 14.21 444.77 17.66 444.58 17.66 449.29"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 458.61 14.21 458.73 14.21 454.03 17.66 453.9 17.66 458.61"
                  />
                  <polygon
                    class="cls-1"
                    points="17.66 467.93 14.21 467.99 14.21 463.29 17.66 463.22 17.66 467.93"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="12.93 255.64 9.48 257.17 9.48 252.46 12.93 250.94 12.93 255.64"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 283.34 9.48 284.68 9.48 279.97 12.93 278.64 12.93 283.34"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 301.81 9.48 303.02 9.48 298.31 12.93 297.11 12.93 301.81"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 320.28 9.48 321.36 9.48 316.65 12.93 315.57 12.93 320.28"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 347.98 9.48 348.87 9.48 344.16 12.93 343.27 12.93 347.98"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 357.21 9.48 358.04 9.48 353.33 12.93 352.51 12.93 357.21"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 375.68 9.48 376.38 9.48 371.67 12.93 370.97 12.93 375.68"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 384.91 9.48 385.55 9.48 380.84 12.93 380.21 12.93 384.91"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 394.15 9.48 394.72 9.48 390.01 12.93 389.44 12.93 394.15"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 403.38 9.48 403.89 9.48 399.18 12.93 398.67 12.93 403.38"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 421.85 9.48 422.23 9.48 417.52 12.93 417.14 12.93 421.85"
                  />
                  <polygon
                    class="cls-1"
                    points="12.93 458.78 9.48 458.91 9.48 454.2 12.93 454.08 12.93 458.78"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="8.19 266.88 4.74 268.34 4.74 263.63 8.19 262.17 8.19 266.88"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 276.03 4.74 277.42 4.74 272.72 8.19 271.32 8.19 276.03"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 285.18 4.74 286.51 4.74 281.8 8.19 280.47 8.19 285.18"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 294.32 4.74 295.59 4.74 290.88 8.19 289.61 8.19 294.32"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 303.47 4.74 304.67 4.74 299.97 8.19 298.76 8.19 303.47"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 312.61 4.74 313.75 4.74 309.05 8.19 307.91 8.19 312.61"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 321.76 4.74 322.84 4.74 318.13 8.19 317.05 8.19 321.76"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 330.91 4.74 331.92 4.74 327.22 8.19 326.2 8.19 330.91"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 358.35 4.74 359.17 4.74 354.46 8.19 353.64 8.19 358.35"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 376.64 4.74 377.34 4.74 372.63 8.19 371.93 8.19 376.64"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 385.78 4.74 386.42 4.74 381.71 8.19 381.08 8.19 385.78"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 404.08 4.74 404.58 4.74 399.88 8.19 399.37 8.19 404.08"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 413.22 4.74 413.67 4.74 408.96 8.19 408.52 8.19 413.22"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 422.37 4.74 422.75 4.74 418.04 8.19 417.66 8.19 422.37"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 431.52 4.74 431.83 4.74 427.13 8.19 426.81 8.19 431.52"
                  />
                  <polygon
                    class="cls-1"
                    points="8.19 449.81 4.74 450 4.74 445.29 8.19 445.1 8.19 449.81"
                  />
                </g>
                <g>
                  <polygon
                    class="cls-1"
                    points="3.45 259.83 0 261.35 0 256.64 3.45 255.12 3.45 259.83"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 268.88 0 270.34 0 265.64 3.45 264.18 3.45 268.88"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 277.94 0 279.34 0 274.63 3.45 273.24 3.45 277.94"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 287 0 288.34 0 283.63 3.45 282.3 3.45 287"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 296.06 0 297.33 0 292.63 3.45 291.36 3.45 296.06"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 305.12 0 306.33 0 301.62 3.45 300.42 3.45 305.12"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 332.3 0 333.32 0 328.61 3.45 327.6 3.45 332.3"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 341.36 0 342.31 0 337.61 3.45 336.65 3.45 341.36"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 359.48 0 360.3 0 355.6 3.45 354.77 3.45 359.48"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 386.65 0 387.29 0 382.59 3.45 381.95 3.45 386.65"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 395.72 0 396.29 0 391.58 3.45 391.01 3.45 395.72"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 413.83 0 414.28 0 409.57 3.45 409.13 3.45 413.83"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 431.95 0 432.27 0 427.56 3.45 427.25 3.45 431.95"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 441.01 0 441.27 0 436.56 3.45 436.31 3.45 441.01"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 450.07 0 450.26 0 445.56 3.45 445.36 3.45 450.07"
                  />
                  <polygon
                    class="cls-1"
                    points="3.45 459.13 0 459.26 0 454.55 3.45 454.42 3.45 459.13"
                  />
                </g>
              </svg>
            </figure>
            </footer>
        </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import mixinsTemplates from './mixinsTemplates.js'
  export default {
    name: "templateAgenteTres",
    mixins: [mixinsTemplates],
    watch: {
      'colors.color_fondo': function(newColor) {
        const svgContainer = this.$refs.svgContainer;
        if (svgContainer instanceof Element) {
          // Modifica el estilo del SVG con el nuevo color de fondo
          const svg = svgContainer.querySelector('.CasaSvg path');
          if (svg) {
            svg.style.fill = newColor;
          }
        }
      },
    },
  };
</script>

<style lang="css" scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;
  }
  body {
      font-family: "Montserrat";
  }
  .template2 {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  /*View template*/
  .viewTemplate {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      max-width: 590px;
      color: var(--font);
      box-shadow: 3px 3px 10px -1px #00000026;
      background-color: #fff;
  }
  /*HEADER*/
  .viewTemplate__header {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 0 10px;
      padding-top: 10px;
  }

  .header__porfileImg,
  .footer__company {
      width: 110px;
      height: 110px;
      transition: all 0.3s ease-in-out;
  }
  .header__porfileImg img,
  .footer__company img {
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  .header__infoCon h1 {
      font-size: 2rem;
  }
  .header__infoCon h2 {
      font-size: 1.6rem;
  }
  .header__infoCon div p {
      font-size: 1rem;
      margin-bottom: 0px;
  }
  .header__infoCon__person {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
  }

  .header__infoCon__place {
    border-top: 2px solid var(--bg);
    padding-top: 10px;
  }

  .socialMedia {
    display: flex;
  }

  .linkIcon {
      width: 20px;
      height: 22px;
  }
  .linkIcon svg {
      width: 100%;
      height: 100%;
      fill: var(--bg);
  }

  /*MAIN*/
  .descripcionPerfil {
      width: 70%;
      margin-left: 34px;
      padding: 34px 0px;
      font-size: 1.1rem;
      text-align: justify;
      color: var(--font);
  }

  /*FOOTER*/
  .viewTemplate__footer {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
  }

  .footer__imgDec {
      position: absolute;
      right: calc(50% - 800px);
      bottom: -11px;
      width: 214px;
      /* right: 4px;
      bottom: -64px;
      width: 278px; */
      transition: all 0.3s ease-in-out;
  }
  .footer__imgDec svg{
      fill: var(--bg);
  }

  @media screen and (min-width: 480px) {
      .footer__imgDec {
        right: calc(50% - 511px);
        bottom: -31px;
        width: 224px;
      }

      .header__porfileImg,
      .footer__company {
        width: 110px;
        height: 110px;
      }
  }
</style>